<mat-card class="card-table" fxFlex="0 1 calc(100%)">
  <mat-card-content>
    <div fxLayout="row wrap" fxLayoutAlign="center">
      <mat-table [dataSource]="dataSource" matSort fxFlex="0 1 calc(100%)">
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
            &nbsp;
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="icon">
          <mat-header-cell *matHeaderCellDef> </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-icon>{{ icon }}</mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container *ngFor="let model of models" [matColumnDef]="model.attribute">
          <mat-header-cell *matHeaderCellDef mat-sort-header [fxFlex]="model.size" [fxHide.gt-sm]="!model.display || model.display === 'MOBILE'" [fxHide.lt-md]="!model.display || model.display === 'DESKTOP'">
            {{ model.label | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element" [fxFlex]="model.size" [fxHide.gt-sm]="!model.display || model.display === 'MOBILE'" [fxHide.lt-md]="!model.display || model.display === 'DESKTOP'">
            <span *ngIf="model.type === 'string'">{{ getValue(element, model.attribute) }}</span>
            <span *ngIf="model.type === 'date'">{{
              getValue(element, model.attribute) | date: 'medium'
            }}</span>
            <span *ngIf="model.type === 'duration'">{{
              getValue(element, model.attribute) | durationFormat: 'ms':'medium'
            }}</span>
            <span *ngIf="model.type === 'number'">{{
              getValue(element, model.attribute) | number: '1.3-3'
            }}</span>
            <span *ngIf="model.type === 'color'">
                <span class="light" style.background-color="{{getValue(element, model.attribute)}}">
                </span>
            </span>
            <span *ngIf="model.type === 'function'">
              <div [innerHtml]="model.function ? model.function(element) : ''"></div>
              </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="filter-icon">
          <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        </ng-container>
        <ng-container matColumnDef="filter-select">
          <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        </ng-container>
        <mat-header-row
          [ngClass]="attributeFilters ? 'headerWithFilter' : ''"
          *matHeaderRowDef="displayedColumns"
        ></mat-header-row>
        <div *ngIf="attributeFilters">
          <mat-header-row
            style="min-height: 30px !important"
            *matHeaderRowDef="filteredColumns"
          ></mat-header-row>
        </div>
        <mat-row
          class="element-row"
          *matRowDef="let row; columns: displayedColumns"
          (click)="multiSelectActive ? selection.toggle(row) : ''; popUpComponent ? popUpDialog(row) : navigateTo(row)"
          mat-ripple
          matRipple
        >
        </mat-row>
        <div *matNoDataRow>
          {{ (loaderService.loader$ | async) ?  ('list.loading' | translate) : ('list.noItems' | translate) }}
        </div>
      </mat-table>
      <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
      <mat-paginator [length]="resultsLength" [pageSizeOptions]="[25, 50, 100]" fxFlex> </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
