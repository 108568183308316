import { Component, Input, Output, OnInit, OnChanges, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, tap, switchMap } from 'rxjs/operators';
import { GraphService } from 'src/app/modules/shared';
import { of } from 'rxjs';
import { LanguageService, SelectionService } from '../../services';
const omitDeep = require("omit-deep-lodash");

@Component({
  selector: 'app-search-people-digital-champion',
  templateUrl: './searchPeopleDigitalChampion.component.html',
  styleUrls: ['./searchPeopleDigitalChampion.component.scss']
})
export class searchPeopleDigitalChampionComponent implements OnInit {
  @Input() selected: any | null | undefined;
  @Input() formName: string

  @Output() selectedChange: EventEmitter<any[]> = new EventEmitter();
 
  constructor(
    public selectionService: SelectionService,
    public languageService: LanguageService
  )
  {}

  digitalChampionsForm = new FormGroup({
    role: new FormControl( [Validators.required]),
  });

  ngOnInit(): void {
    this.selectionService.selectionsDigitalChampionDomain$.subscribe(digitalChampions => {
      this.digitalChampionsForm.controls['role'].setValue(digitalChampions[0])
    })
  }

  changeDigitalChampion() {
    if (this.selected) {
      this.selected["role"] = this.cleanData(this.digitalChampionsForm.value['role'])
    }
  }

  trackByFn(index: number, item: any) {
    return item.id;
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1 && o2 && o1.id === o2.id && o1.id === o2.id;
  }

  updateContact(evt) {
    this.selected["role"] = this.cleanData(this.digitalChampionsForm.value['role'])
    this.selectedChange.emit(this.selected)
  }

  cleanData(item: any): any {
    return omitDeep(item, '_ts', '_self', '_attachments', '_etag', '_rid', '__typename')
  }
}
