<h1 mat-dialog-title>Upload</h1>
<mat-dialog-content>
  <mat-stepper #stepper linear labelPosition="bottom" style="background-color: transparent">
    <mat-step label="Upload File">
      <br />
      <div *ngIf="file">
        <div class="keyvalue">
          File Name : <strong>{{ file.name }}</strong>
        </div>
        <div class="keyvalue">
          File Size : <strong>{{ file.size / 1024 / 1024 | number: '1.2-2' }}Mo</strong>
        </div>
      </div>
      <br />
      <input
        #csvInput
        type="file"
        hidden="true"
        class="form-control"
        (change)="addfile($event)"
        placeholder="Upload file"
        accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      Supported formats are : <strong>csv</strong>, <strong>xls</strong> and <strong>xlsx</strong><br /><br />
      <button mat-raised-button color="primary" (click)="csvInput.click()">
        Choose Spreadsheet File
      </button>
    </mat-step>
    <mat-step label="Select Columns" [stepControl]="form">
      <div *ngIf="!worksheet">Please select a valid file before</div>
      <div *ngIf="worksheet">
        <form autocomplete="off" #f="ngForm" [formGroup]="form">
          <mat-form-field *ngFor="let attribute of data.attributes">
            <mat-select
              [formControlName]="attribute.name"
              placeholder="{{ 'form.' + attribute.name | translate }}"
            >
              <mat-option *ngFor="let column of sheetColumns" [value]="column">
                {{ column }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </mat-step>
    <mat-step label="Upload">
      <mat-checkbox [(ngModel)]="full"> Full </mat-checkbox>
      <div class="keyvalue">
        Total in file : <strong>{{ total }}</strong>
      </div>
      <div class="keyvalue">
        Total in db : <strong>{{ destination }}</strong>
      </div>
      <div class="keyvalue">
        Identical : <strong>{{ identical }}</strong>
      </div>
      <div class="keyvalue">
        To Update : <strong>{{ update }}</strong>
      </div>
      <div class="keyvalue">
        To Create : <strong>{{ create }}</strong>
      </div>
      <div class="keyvalue">
        To Delete : <strong>{{ delete }}</strong>
      </div>
      <br />
      <div class="keyvalue">
        Progress : <strong>{{ done || 0 }}/{{ full ? source : update + create }}</strong>
      </div>
      <mat-progress-bar
        mode="determinate"
        value="{{ ((done || 0) / (full ? source : update + create)) * 100 }}"
      >
      </mat-progress-bar>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="accent" mat-dialog-close>{{ 'dialog.cancel' | translate }}</button>
  <button
    mat-raised-button
    color="primary"
    *ngIf="stepper.selectedIndex === 0"
    (click)="stepper.next()"
    [disabled]="!file"
  >
    Next
  </button>
  <button
    mat-raised-button
    color="primary"
    *ngIf="stepper.selectedIndex === 1"
    (click)="generateList(); stepper.next()"
    [disabled]="form.invalid"
  >
    Next
  </button>
  <button
    mat-raised-button
    color="primary"
    *ngIf="stepper.selectedIndex === 2"
    [disabled]="!((itemList?.length || 0) > 0)"
    (click)="full ? uploadFull() : upload()"
  >
    Upload
  </button>
</mat-dialog-actions>
