import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnChanges,
  AfterViewInit,
  EventEmitter,
  Output
} from '@angular/core';
import { MapElement } from 'src/app/modules/shared';
import { first } from 'rxjs/operators';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-maps-google',
  templateUrl: './maps-google.component.html',
  styleUrls: ['./maps-google.component.scss']
})
export class MapsGoogleComponent implements OnInit, OnChanges, AfterViewInit {
  constructor(public httpClient: HttpClient) { }

  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  infoContent: MapElement | null = null;
  @ViewChild(GoogleMap, { static: false }) map!: GoogleMap;
  @Input() markers: MapElement[] = [];
  apiLoaded: boolean;
  @Input() height = 540;
  me = { path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW, scale: 5 };
  markerOptions: google.maps.MarkerOptions = {
    //animation: google.maps.Animation.BOUNCE
  };
  latitude = 0;
  longitude = 0;
  zoomOption = 15;
  @Input() key = 'maps';
  @Input() url = '/master/sites/'
  options: google.maps.MapOptions = {
    center: { lat: 40.7637059, lng: -73.9879797 },
    mapTypeId: 'roadmap', //hybrid
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 2,
    disableDefaultUI: true
  };
  select!: number;
  bounds: any;
  @Output() eventAction: EventEmitter<{
    id: string;
    type: string;
    items: any[];
  }> = new EventEmitter();

  ngOnInit() {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.centerAuto();
    }, 200);
  }
  async ngOnChanges(changes: any) {
    for (const propName in changes) {
      // eslint-disable-next-line no-prototype-builtins
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'markers': {
            const newBounds = this.generateBounds();
            if (
              this.bounds?.west !== newBounds.west ||
              this.bounds?.east !== newBounds.east ||
              this.bounds?.north !== newBounds.north ||
              this.bounds?.south !== newBounds.south
            ) {
              this.centerAuto();
            }
            break;
          }
        }
      }
    }
  }

  zoomIn() {
    if (this.zoomOption < (this.options?.maxZoom || 15)) this.zoomOption++;
  }

  zoomOut() {
    if (this.zoomOption > (this.options.minZoom || 3)) this.zoomOption--;
  }

  generateBounds() {
    let north = 0;
    let south = 0;
    let east = 0;
    let west = 0;
    for (const marker of this.markers) {
      north = north !== 0 ? Math.max(north, marker?.latitude) : marker?.latitude;
      south = south !== 0 ? Math.min(south, marker?.latitude) : marker?.latitude;
      east = east !== 0 ? Math.max(east, marker?.longitude) : marker?.longitude;
      west = west !== 0 ? Math.min(west, marker?.longitude) : marker?.longitude;
    }

    return { north, south, east, west };
  }
  centerAuto() {
    this.bounds = this.generateBounds();
    this.map?.googleMap?.fitBounds(this.bounds);
    if (this.map) this.zoomOption = this.map.getZoom();
  }
  openInfoWindow(marker: MapMarker, content: MapElement) {
    this.infoContent = content;
    this.infoWindow.open(marker);
  }

  trackByFn(index: number, item: any) {
    return item.title;
  }
}
