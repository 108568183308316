import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { CreateSmartRInput, SmartR, LoaderService, UpdateSmartRInput } from 'src/app/modules/shared';
import { map } from 'rxjs/operators';
import { SignalrService } from './signalr.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SmartRService {
  private readonly _smartRsSource = new BehaviorSubject<SmartR[]>([]);
  readonly smartRs$ = this._smartRsSource.asObservable();

  constructor(
    private apollo: Apollo,
    private signalrService: SignalrService,
    private loaderService: LoaderService
  ) {
    this.refresh();
    this.signalrService.connection.on('smartRs', (element) => {
      this.refresh();
    });
  }
  getValue() {
    return this._smartRsSource.getValue();
  }
  async refresh() {
    this.loaderService.addLoader('smartR', 'list');
    const REQ = gql`
      query listSmartRs {
        listSmartRs {
          id
          code
          siteName
          street1
          street2
          street3
          city
          postalCode
          state
          country
        }
      }
    `;
    this._smartRsSource.next(
      await this.apollo
        .query<any>({
          query: REQ,
          variables: {}
        })
        .pipe(map((result) => {
          this.loaderService.removeLoader('smartR', 'list');
          return _.sortBy(result?.data?.listSmartRs || [], 'Code')
        }))
        .toPromise()
    );
  }
  async create(smartR: CreateSmartRInput, broadcast: boolean) {
    this.loaderService.addLoader('smartR', 'create');
    const REQ = gql`
      mutation createSmartR($input: CreateSmartRInput!, $broadcast: Boolean!) {
        createSmartR(input: $input, broadcast: $broadcast)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          input: smartR,
          broadcast: broadcast
        }
      })
      .toPromise();
    this.loaderService.removeLoader('smartR', 'create');
  }
  async update(smartR: UpdateSmartRInput) {
    this.loaderService.addLoader('smartR', 'update');
    const REQ = gql`
      mutation updateSmartR($input: UpdateSmartRInput!) {
        updateSmartR(input: $input)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          input: smartR
        }
      })
      .toPromise();
    this.loaderService.removeLoader('smartR', 'update');
  }
  async delete(smartRs: SmartR[]) {
    this.loaderService.addLoader('smartR', 'delete');
    const REQ = gql`
      mutation deleteSmartRs($smartRIds: [ID!]) {
        deleteSmartRs(smartRIds: $smartRIds)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          smartRIds: smartRs.map((smartR) => smartR.id)
        }
      })
      .toPromise();
    this.loaderService.removeLoader('smartR', 'delete');
  }
}
