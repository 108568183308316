import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { CreateBusinessUnitInput, BusinessUnit, LoaderService, UpdateBusinessUnitInput } from 'src/app/modules/shared';
import { map } from 'rxjs/operators';
import { SignalrService } from './signalr.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class BusinessUnitService {
  private readonly _businessUnitsSource = new BehaviorSubject<BusinessUnit[]>([]);
  readonly businessUnits$ = this._businessUnitsSource.asObservable();

  constructor(
    private apollo: Apollo,
    private signalrService: SignalrService,
    private loaderService: LoaderService
  ) {
    this.refresh();
    this.signalrService.connection.on('businessUnits', () => {
      this.refresh();
    });
  }
  getValue() {
    return this._businessUnitsSource.getValue();
  }
  async refresh() {
    this.loaderService.addLoader('businessUnit', 'list');
    const REQ = gql`
      query listBusinessUnits {
        listBusinessUnits {
          id
          title
          cio {
            userPrincipalName
            givenName
            surname
            mail
            mobilePhone
          }
        }
      }
    `;
    this._businessUnitsSource.next(
      await this.apollo
        .query<any>({
          query: REQ,
          variables: {}
        })
        .pipe(map((result) => {
          this.loaderService.removeLoader('businessUnit', 'list');
          return _.sortBy(result?.data?.listBusinessUnits || [], 'bu')
        }))
        .toPromise()
    );
  }
  async create(businessUnit: CreateBusinessUnitInput, broadcast: boolean = true) {
    this.loaderService.addLoader('businessUnit', 'create');
    const REQ = gql`
      mutation createBusinessUnit($input: CreateBusinessUnitInput!, $broadcast: Boolean!) {
        createBusinessUnit(input: $input, broadcast: $broadcast)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          input: businessUnit,
          broadcast: broadcast
        }
      })
      .toPromise();
    this.loaderService.removeLoader('businessUnit', 'create');
  }
  async update(businessUnit: UpdateBusinessUnitInput) {
    this.loaderService.addLoader('businessUnit', 'update');
    const REQ = gql`
      mutation updateBusinessUnit($input: UpdateBusinessUnitInput!) {
        updateBusinessUnit(input: $input)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          input: businessUnit
        }
      })
      .toPromise();
    this.loaderService.removeLoader('businessUnit', 'update');
  }
  async delete(businessUnits: BusinessUnit[]) {
    this.loaderService.addLoader('businessUnit', 'delete');
    const REQ = gql`
      mutation deleteBusinessUnits($businessUnitIds: [ID!]) {
        deleteBusinessUnits(businessUnitIds: $businessUnitIds)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          businessUnitIds: businessUnits.map((businessUnit) => businessUnit.id)
        }
      })
      .toPromise();
    this.loaderService.removeLoader('businessUnit', 'delete');
  }
}
