import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService, TranslateLoader } from '@ngx-translate/core';
import { TranslationService } from './translation.service';
import { first, map } from 'rxjs/operators';
import * as _ from 'lodash';
//import { UserService } from './user.service';
import { AuthService } from './auth-azure.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private language = 'us';
  private client = 'default';

  constructor(
    //private userService: UserService,
    private authService: AuthService,
    private translate: TranslateService
  ) {
    this.translate.use(this.client + '_' + this.language);
  }

  async setLanguage(language: string) {
    this.language = language;
    this.translate.use(this.client + '_' + this.language);
  }

  async changeLanguage(language: string) {
    this.setLanguage(language);
    /*
    this.authService.userValue?.id &&
      this.userService
        .update({
          id: this.authService.userValue?.id,
          email: this.authService.userValue?.email,
          language: language
        })
        .subscribe();
        */
  }

  async setDictionary(client: string) {
    this.client = client;
    this.translate.use(this.client + '_' + this.language);
  }

  get currentDictionary() {
    return this.client;
  }

  get currentLanguage() {
    return this.language;
  }

  getTranslation(key: string | Array<string>): Observable<any> {
    return this.translate.get(key);
  }

  getInstantTranslation(key: string | Array<string>) {
    return this.translate?.instant(key);
  }
}

@Injectable()
export class LanguageLoader implements TranslateLoader {
  constructor(private injector: Injector) { }

  getTranslation(lang: string): Observable<any> {
    const translationService = this.injector.get(TranslationService);
    return translationService.translation$.pipe(
      first((val) => val?.length > 0),
      map((data) => {
        const translationClient: any = {};
        data
          .filter((translation) => translation.client + '_' + translation.language === lang)
          .forEach((translation) => {
            translationClient[translation.subCategory + '.' + translation.key] = translation.value;
          });
        const translationDefault: any = {};
        data
          .filter(
            (translation) =>
              translation.language === lang.split('_')[1] && translation.client === 'default'
          )
          .forEach((translation) => {
            translationDefault[translation.subCategory + '.' + translation.key] = translation.value;
          });

        return _.assign({}, translationDefault, translationClient);
      })
    );
  }
}
