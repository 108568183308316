import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { CreateSubBusinessUnitContactInput, SubBusinessUnitContact, LoaderService, UpdateSubBusinessUnitContactInput } from 'src/app/modules/shared';
import { map } from 'rxjs/operators';
import { SignalrService } from './signalr.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SubBusinessUnitContactService {
  private readonly _subBusinessUnitContactsSource = new BehaviorSubject<SubBusinessUnitContact[]>([]);
  readonly listSubBusinessUnitContacts$ = this._subBusinessUnitContactsSource.asObservable();

  constructor(
    private apollo: Apollo,
    private signalrService: SignalrService,
    private loaderService: LoaderService
  ) {
    this.refresh();
    this.signalrService.connection.on('subBusinessUnitContacts', () => {
      this.refresh();
    });
  }
  getValue() {
    return this._subBusinessUnitContactsSource.getValue();
  }
  async refresh() {
    this.loaderService.addLoader('subBusinessUnitContact', 'list');
    const REQ = gql`
      query listSubBusinessUnitContacts {
        listSubBusinessUnitContacts {
          id
          scope {
            id
            code
            title
          }
          role {
            id
            code
            title
          }
          user {
            userPrincipalName
            givenName
            surname
            mail
            mobilePhone
          }
          subBusinessUnit{
            id
            title
            businessUnit {
              id
              title
            }
          }
        }
      }
    `;
    this._subBusinessUnitContactsSource.next(
      await this.apollo
        .query<any>({
          query: REQ,
          variables: {}
        })
        .pipe(map((result) => {
          this.loaderService.removeLoader('subBusinessUnitContact', 'list');
          return _.sortBy(result?.data?.listSubBusinessUnitContacts || [], 'bu')
        }))
        .toPromise()
    );
  }
  async create(subBusinessUnitContact: CreateSubBusinessUnitContactInput, broadcast: boolean = true) {
    this.loaderService.addLoader('subBusinessUnitContact', 'create');
    const REQ = gql`
      mutation createSubBusinessUnitContact($input: CreateSubBusinessUnitContactInput!, $broadcast: Boolean!) {
        createSubBusinessUnitContact(input: $input, broadcast: $broadcast)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          input: subBusinessUnitContact,
          broadcast: broadcast
        }
      })
      .toPromise();
    this.loaderService.removeLoader('subBusinessUnitContact', 'create');
  }
  async update(subBusinessUnitContact: UpdateSubBusinessUnitContactInput) {
    this.loaderService.addLoader('subBusinessUnitContact', 'update');
    const REQ = gql`
      mutation updateSubBusinessUnitContact($input: UpdateSubBusinessUnitContactInput!) {
        updateSubBusinessUnitContact(input: $input)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          input: subBusinessUnitContact
        }
      })
      .toPromise();
    this.loaderService.removeLoader('subBusinessUnitContact', 'update');
  }
  async delete(subBusinessUnitContacts: SubBusinessUnitContact[]) {
    this.loaderService.addLoader('subBusinessUnitContact', 'delete');
    const REQ = gql`
      mutation deleteSubBusinessUnitContacts($subBusinessUnitContactIds: [ID!]) {
        deleteSubBusinessUnitContacts(subBusinessUnitContactIds: $subBusinessUnitContactIds)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          subBusinessUnitContactIds: subBusinessUnitContacts.map((subBusinessUnitContact) => subBusinessUnitContact.id)
        }
      })
      .toPromise();
    this.loaderService.removeLoader('subBusinessUnitContact', 'delete');
  }
}
