import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'durationFormat',
  pure: false
})
export class DurationFormatPipe implements PipeTransform {
  transform(value: any, arg1: string, arg2: string): any {
    let days: any;
    let seconds: any;
    let minutes: any;
    let hours: any;
    if (arg1 === 'ms') {
      seconds = Math.floor((value / 1000) % 60);
      minutes = Math.floor((value / (1000 * 60)) % 60);
      hours = Math.floor((value / (1000 * 60 * 60)) % 24);
      days = Math.floor(value / (1000 * 60 * 60 * 24));
      return this.format(arg2, seconds, minutes, hours, days);
    } else if (arg1 === 's') {
      seconds = Math.floor(value % 60);
      minutes = Math.floor((value / 60) % 60);
      hours = Math.floor((value / 60 / 60) % 24);
      days = Math.floor(value / 60 / 60 / 24);
      return this.format(arg2, seconds, minutes, hours, days);
    } else {
      return value;
    }
  }

  format(arg2: string, seconds: any, minutes: any, hours: any, days: any): string {
    if (days < 10)
      days = '0' + days
      
    if (hours < 10)
      hours = '0' + hours
      
    if (minutes < 10)
      minutes = '0' + minutes

    if (seconds < 10)
      seconds = '0' + seconds

    let result = '';
    if (days > 0) result += days;
    if (days > 0 && arg2 === 'small') result += ':';
    if (days > 0 && arg2 === 'medium') result += 'd ';
    if (days > 0 && arg2 === 'long') result += ' days, ';
    if (hours > 0 || days > 0) result += hours;
    if ((hours > 0 || days > 0) && arg2 === 'small') result += ':';
    if ((hours > 0 || days > 0) && arg2 === 'medium') result += 'h ';
    if ((hours > 0 || days > 0) && arg2 === 'long') result += ' hours, ';
    result += minutes;
    if (arg2 === 'small') result += ':';
    if (arg2 === 'medium') result += 'm ';
    if (arg2 === 'long') result += ' minutes, ';
    result += seconds;
    if (arg2 === 'small') result += ':';
    if (arg2 === 'medium') result += 's ';
    if (arg2 === 'long') result += ' seconds, ';
    return result;
  }
}
