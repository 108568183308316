import { Component, Input, Output, OnInit, OnChanges, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, tap, switchMap } from 'rxjs/operators';
import { GraphService } from 'src/app/modules/shared';
import { of } from 'rxjs';

@Component({
  selector: 'app-search-people-on-site',
  templateUrl: './searchPeopleOnSite.component.html',
  styleUrls: ['./searchPeopleOnSite.component.scss']
})
export class SearchPeopleOnSiteComponent implements OnInit{
  @Input() selected: any | null | undefined;
  @Input() formName: string

  @Output() selectedChange: EventEmitter<any[]> = new EventEmitter();

  checked = false;

  async ngOnInit() {
  if (this.selected && this.selected?.onSite)
    this.checked = this.selected.onSite
  }

  changeOnSite() {
    if (this.selected) {
      this.selected["onSite"] = this.checked
    }
  }

  updateContact(evt) {
    this.selected["onSite"] = this.checked
    this.selectedChange.emit(this.selected)
  }
}