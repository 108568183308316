<div class="mapcontainer">
  <google-map class="map" [zoom]="zoomOption" [options]="options" width="100%" height="{{ height }}px">
    <map-marker labelClass="my-custom-class-for-label" #markerElem="mapMarker"
      *ngFor="let marker of markers; trackBy: trackByFn" [position]="{ lat: marker.latitude, lng: marker.longitude }"
      [title]="marker.title || ''" [label]="''" [options]="markerOptions"
      (mapClick)="openInfoWindow(markerElem, marker)">
    </map-marker>
    <map-info-window>
      <div class="list-item" style="width: 250px">
        <mat-icon mat-list-avatar class="icon-small">domain</mat-icon>
        <div class="list-item-content-full">
          <h4>{{ infoContent?.title }}</h4>
          <p>{{ infoContent?.address }}</p>
        </div>
        <span class="spacer"></span>
        <div class="list-item-actions">
          <button mat-icon-button [routerLink]="url + infoContent?.id">
            <mat-icon>search</mat-icon>
          </button>
        </div>
      </div>
    </map-info-window>
  </google-map>
  <div class="map" *ngIf="longitude === 99">
    Looking for your localisation...
    <br /><br />
    <mat-spinner></mat-spinner>
  </div>
  <button mat-icon-button (click)="zoomIn()">
    <mat-icon>zoom_in</mat-icon>
  </button>
  <button mat-icon-button (click)="zoomOut()">
    <mat-icon>zoom_out</mat-icon>
  </button>
  <button mat-icon-button (click)="centerAuto()">
    <mat-icon>center_focus_weak</mat-icon>
  </button>
</div>