import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { InMemoryCache } from '@apollo/client/core';
import { ApolloLink } from 'apollo-link';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';

import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/modules/shared';

const uri = 'api/graphql';
const debug = false;
const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  },
  mutate: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
  //defaultHttpLink: false
};

export function provideApollo(
  httpLink: HttpLink,
  matSnackBar: MatSnackBar,
  authService: AuthService,
) {
  const auth = setContext(async (_, { headers }) => {
    //let token = authService.tokenValue
    const token = localStorage.getItem('token')
    if (!token) {
      return {
        headers: {
          Accept: 'charset=utf-8'
        }
      };
    }
    return {
      headers: {
        Accept: 'charset=utf-8',
        Authorization: `Bearer ${token}`,
        //FeaturesToken: featuresToken
      }
    };
  });
  const error = onError(({ graphQLErrors, networkError, response, operation }) => {
    // Checking GraphQL Errors
    if (graphQLErrors) {
      graphQLErrors?.map((element: any) => {
        if (element.code === 'INVALID_TOKEN' || element.code === 'UNAUTHENTICATED') {
          localStorage.removeItem('access-token');
          localStorage.removeItem('user');
          localStorage.removeItem('expires-at');
        } else {
          if (debug)
            matSnackBar.open('GQLERR : ' + element.message, '', {
              duration: 4000
            });
        }
      });
    }
    // Checking in Network Errors
    if (networkError) {
      const data = JSON.parse(JSON.stringify(networkError));
      if (data?.error?.errors) {
        data?.error?.errors?.map((element: any) => {
          if (element.code === 'INVALID_TOKEN' || element.code === 'UNAUTHENTICATED') {
            localStorage.removeItem('access-token');
            localStorage.removeItem('user');
            localStorage.removeItem('expires-at');
          } else {
            if (debug)
              matSnackBar.open('GQLERR : ' + element.message, '', {
                duration: 4000
              });
          }
        });
      } else {
        if (debug)
          matSnackBar.open('NETERR : ' + networkError.message, '', {
            duration: 4000
          });
      }
      if (debug) console.log(`[Network Error]:`, networkError);
    }
  });

  const http = httpLink.create({ uri: uri });
  const link = ApolloLink.from([auth, error, http]);
  const cache = new InMemoryCache();

  return {
    link,
    cache,
    defaultOptions
  };
}

@NgModule({
  exports: [HttpClientModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: provideApollo,
      deps: [HttpLink, MatSnackBar, AuthService]
    }
  ]
})
export class GraphQLModule { }

