import { Component, Input, Output, OnInit, OnChanges, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, tap, switchMap } from 'rxjs/operators';
import { LocalisationInput, PlaceData, GoogleMapsService } from 'src/app/modules/shared';
import { of } from 'rxjs';

@Component({
  selector: 'app-search-address',
  templateUrl: './searchAddress.component.html'
})
export class SearchAddressComponent implements OnInit, OnChanges {
  @Input() localisation: LocalisationInput | null | undefined;
  @Input() isReadOnly = false;

  @Output() localisationChange: EventEmitter<LocalisationInput> = new EventEmitter();

  searchPlacesCtrl = new FormControl();
  filteredPlaces: PlaceData[];
  isLoading = false;
  errorMsg: string;

  constructor(private googleMapsService: GoogleMapsService) {}

  ngOnInit() {
    this.searchPlacesCtrl.setValue(this.localisation, { emitEvent: false });
    if (this.isReadOnly) this.searchPlacesCtrl.disable();
    this.searchPlacesCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.errorMsg = '';
          this.filteredPlaces = [];
          this.isLoading = true;
        }),
        switchMap((value) => {
          if (value && value !== '' && typeof value === 'string')
            return this.googleMapsService.search(value);
          else return of(null);
        })
      )
      .subscribe((data) => {
        if (data === undefined || data === null) {
          this.errorMsg = 'error';
          this.filteredPlaces = [];
        } else {
          this.isLoading = false;
          this.errorMsg = '';
          this.filteredPlaces = data;
        }
      });
  }

  async ngOnChanges(changes: any) {
    for (const propName in changes) {
      // eslint-disable-next-line no-prototype-builtins
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'isReadOnly': {
            if (this.isReadOnly === true) {
              this.searchPlacesCtrl.disable();
            } else {
              this.searchPlacesCtrl.enable();
            }
            break;
          }
          case 'localisation': {
            this.searchPlacesCtrl.setValue(this.localisation, { emitEvent: false });
          }
        }
      }
    }
  }

  displayFn(place: PlaceData): string {
    return place?.address || '';
  }

  async result(event: any) {
    if (event?.option?.value) {
      const place = await this.googleMapsService.getPlace(event.option.value.placeId);
      event.option.value.longitude = place?.longitude;
      event.option.value.latitude = place?.latitude;
      event.option.value.streetNumber = place?.streetNumber;
      event.option.value.route = place?.route;
      event.option.value.locality = place?.locality;
      event.option.value.country = place?.country;
      event.option.value.utcOffset = place?.utcOffset;
      this.localisationChange.emit(event.option.value as LocalisationInput);
    }
  }
}
