import { Component } from '@angular/core';
import { LoaderService } from '../../services/loader.service';

import { environment } from './../../../../../environments/environment';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html'
  // styleUrls: ['./loader.component.css']
})
export class LoaderComponent {

  loader_debug = environment.loader_debug

  constructor(public loaderService: LoaderService) {}
}
