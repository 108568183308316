<div fxLayout="row wrap">
    <app-search-people [(selected)]="selected" [formName]="formName" (selectedChange)="updateContact($event)"></app-search-people>
    <form autocomplete="off" #f="ngForm" [formGroup]="digitalChampionsForm">
        <mat-form-field>
            <mat-select
            #sbuSelect
            placeholder="{{'site.digitalChampionDomain' | translate}}"
            formControlName="role"
            [compareWith]="compareObjects"
            (selectionChange)=changeDigitalChampion()
            >
            <mat-option
                *ngFor="let selection of selectionService.selectionsDigitalChampionDomain$ | async"
                [value]="selection"
            >
            {{ selection.title }}
            </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</div>