import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { CreateMagnitudeInput, Magnitude, LoaderService, UpdateMagnitudeInput } from 'src/app/modules/shared';
import { map } from 'rxjs/operators';
import { SignalrService } from './signalr.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MagnitudeService {
  private readonly _magnitudesSource = new BehaviorSubject<Magnitude[]>([]);
  readonly magnitudes$ = this._magnitudesSource.asObservable();

  constructor(
    private apollo: Apollo,
    private signalrService: SignalrService,
    private loaderService: LoaderService
  ) {
    this.refresh();
    this.signalrService.connection.on('magnitudes', (element) => {
      this.refresh();
    });
  }
  getValue() {
    return this._magnitudesSource.getValue();
  }
  async refresh() {
    this.loaderService.addLoader('magnitude', 'list');
    const REQ = gql`
      query listMagnitudes {
        listMagnitudes {
          id
          SRU_Code
          SRU_Name
          Entity_Code
          Entity
          Segment_Code
          Segment
          Business_group_code
          Business_group
          Branche_Code
          Branche
        }
      }
    `;
    this._magnitudesSource.next(
      await this.apollo
        .query<any>({
          query: REQ,
          variables: {}
        })
        .pipe(map((result) => {
          this.loaderService.removeLoader('magnitude', 'list');
          return _.sortBy(result?.data?.listMagnitudes || [], 'SRU_Code')
        }))
        .toPromise()
    );
  }
  async create(magnitude: CreateMagnitudeInput) {
    this.loaderService.addLoader('magnitude', 'create');
    const REQ = gql`
      mutation createMagnitude($input: CreateMagnitudeInput!) {
        createMagnitude(input: $input)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          input: magnitude
        }
      })
      .toPromise();
    this.loaderService.removeLoader('magnitude', 'create');
  }
  async update(magnitude: UpdateMagnitudeInput) {
    this.loaderService.addLoader('magnitude', 'update');
    const REQ = gql`
      mutation updateMagnitude($input: UpdateMagnitudeInput!) {
        updateMagnitude(input: $input)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          input: magnitude
        }
      })
      .toPromise();
    this.loaderService.removeLoader('magnitude', 'update');
  }
  async delete(magnitudes: Magnitude[]) {
    this.loaderService.addLoader('magnitude', 'delete');
    const REQ = gql`
      mutation deleteMagnitudes($magnitudeIds: [ID!]) {
        deleteMagnitudes(magnitudeIds: $magnitudeIds)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          magnitudeIds: magnitudes.map((magnitude) => magnitude.id)
        }
      })
      .toPromise();
    this.loaderService.removeLoader('magnitude', 'delete');
  }
}
