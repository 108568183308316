import { Component, Input, Output, OnInit, OnChanges, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, tap, switchMap } from 'rxjs/operators';
import { GraphService } from 'src/app/modules/shared';
import { of } from 'rxjs';

@Component({
  selector: 'app-search-people-multiple-digital-champion',
  templateUrl: './searchPeopleMultipleDigitalChampions.component.html',
  styleUrls: ['./searchPeopleMultipleDigitalChampions.component.scss']
})
export class searchPeopleMultipleDigitalChampionsComponent {
  @Input() selected = []
  @Input() formName: string
  @Output() selectedChange: EventEmitter<any[]> = new EventEmitter();

  current: any


  addItem() {
    if (this.current) {
      this.selected = [
        this.current,
        ...this.selected
      ]
      this.current = null
    }
    this.selectedChange.emit(this.selected)
  }

  deleteItem(deletedItem) {
    this.selected = this.selected.filter(item => item.mail !== deletedItem.mail)
    this.selectedChange.emit(this.selected)
  }
}