import { Component, Input, Output, OnInit, OnChanges, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, tap, switchMap } from 'rxjs/operators';
import { GraphService } from 'src/app/modules/shared';
import { of } from 'rxjs';

@Component({
  selector: 'app-search-people-first-contact',
  templateUrl: './searchPeopleFirstContact.component.html',
  styleUrls: ['./searchPeopleFirstContact.component.scss']
})
export class SearchPeopleFirstContactComponent implements OnInit{
  @Input() selected: any | null | undefined;
  @Input() formName: string

  @Output() selectedChange: EventEmitter<any[]> = new EventEmitter();

  checked = false;

  async ngOnInit() {
  if (this.selected && this.selected?.firstContact)
    this.checked = this.selected.firstContact
  }

  changeFirstContact() {
    if (this.selected) {
      this.selected["firstContact"] = this.checked
    }
  }

  updateContact(evt) {
      this.selected["firstContact"] = this.checked
      this.selectedChange.emit(this.selected)
  }
}
