import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { CreateSelectionInput, Selection, LoaderService, UpdateSelectionInput } from 'src/app/modules/shared';
import { map } from 'rxjs/operators';
import { SignalrService } from './signalr.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SelectionService {
  private readonly _selectionsSource = new BehaviorSubject<Selection[]>([]);
  readonly selections$ = this._selectionsSource.asObservable();
  private readonly _selectionsBooleanSource = new BehaviorSubject<Selection[]>([]);
  readonly selectionsBoolean$ = this._selectionsBooleanSource.asObservable();
  private readonly _selectionsOperationalSource = new BehaviorSubject<Selection[]>([]);
  readonly selectionsOperational$ = this._selectionsOperationalSource.asObservable();
  private readonly _selectionsFunctionalitySource = new BehaviorSubject<Selection[]>([]);
  readonly selectionsFunctionality$ = this._selectionsFunctionalitySource.asObservable();
  private readonly _selectionsConnectivitySource = new BehaviorSubject<Selection[]>([]);
  readonly selectionsConnectivity$ = this._selectionsConnectivitySource.asObservable();
  private readonly _selectionsAccessibilitySource = new BehaviorSubject<Selection[]>([]);
  readonly selectionsAccessibility$ = this._selectionsAccessibilitySource.asObservable();
  private readonly _selectionsAttackerSource = new BehaviorSubject<Selection[]>([]);
  readonly selectionsAttacker$ = this._selectionsAttackerSource.asObservable();
  private readonly _selectionsClassSource = new BehaviorSubject<Selection[]>([]);
  readonly selectionsClass$ = this._selectionsClassSource.asObservable();
  private readonly _selectionsActivitySource = new BehaviorSubject<Selection[]>([]);
  readonly selectionsActivity$ = this._selectionsActivitySource.asObservable();
  private readonly _selectionsDisplayLocSource = new BehaviorSubject<Selection[]>([]);
  readonly selectionsDisplayLoc$ = this._selectionsDisplayLocSource.asObservable();
  private readonly _selectionsPeopleRangeSource = new BehaviorSubject<Selection[]>([]);
  readonly selectionsPeopleRange$ = this._selectionsPeopleRangeSource.asObservable();
  private readonly _selectionsSgtsAssignementSource = new BehaviorSubject<Selection[]>([]);
  readonly selectionsSgtsAssignement$ = this._selectionsSgtsAssignementSource.asObservable();
  private readonly _selectionsRegionSource = new BehaviorSubject<Selection[]>([]);
  readonly selectionsRegion$ = this._selectionsRegionSource.asObservable();
  private readonly _selectionsDigitalChampionDomainSource = new BehaviorSubject<Selection[]>([]);
  readonly selectionsDigitalChampionDomain$ = this._selectionsDigitalChampionDomainSource.asObservable();
  private readonly _selectionsSiteStandardStatusSource = new BehaviorSubject<Selection[]>([]);
  readonly selectionsSiteStandardStatus$ = this._selectionsSiteStandardStatusSource.asObservable();
  private readonly _selectionsSubBusinessUnitContactRoleSource = new BehaviorSubject<Selection[]>([]);
  readonly selectionsSubBusinessUnitContactRole$ = this._selectionsSubBusinessUnitContactRoleSource.asObservable();
  constructor(
    private apollo: Apollo,
    private signalrService: SignalrService,
    private loaderService: LoaderService
  ) {
    this.refresh();
    this.signalrService.connection.on('selections', () => {
      this.refresh();
    });
  }
  getValue() {
    return this._selectionsSource.getValue();
  }
  async refresh() {
    this.loaderService.addLoader('selection', 'list');
    const REQ = gql`
      query listSelections {
        listSelections {
          id
          code
          title
          type
          color
        }
      }
    `;
    await this.apollo
      .query<any>({
        query: REQ,
        variables: {}
      })
      .pipe(map((result) => {
        this.loaderService.removeLoader('selection', 'list');
        const selections = _.orderBy(result?.data?.listSelections, 'code') || []
        this._selectionsSource.next(selections)
        this._selectionsBooleanSource.next(selections.filter(selection => selection?.type === 'BOOLEAN'))
        this._selectionsOperationalSource.next(selections.filter(selection => selection?.type === 'OPERATIONAL'))
        this._selectionsFunctionalitySource.next(selections.filter(selection => selection?.type === 'FUNCTIONALITY'))
        this._selectionsConnectivitySource.next(selections.filter(selection => selection?.type === 'CONNECTIVITY'))
        this._selectionsAccessibilitySource.next(selections.filter(selection => selection?.type === 'ACCESSIBILITY'))
        this._selectionsAttackerSource.next(selections.filter(selection => selection?.type === 'ATTACKER'))
        this._selectionsClassSource.next(selections.filter(selection => selection?.type === 'CLASS'))
        this._selectionsActivitySource.next(selections.filter(selection => selection?.type === 'ACTIVITY'))
        this._selectionsDisplayLocSource.next(selections.filter(selection => selection?.type === 'DISPLAY_LOC'))
        this._selectionsPeopleRangeSource.next(selections.filter(selection => selection?.type === 'PEOPLE_RANGE'))
        this._selectionsSgtsAssignementSource.next(selections.filter(selection => selection?.type === 'SGTS_ASSIGNEMENT'))
        this._selectionsRegionSource.next(selections.filter(selection => selection?.type === 'REGION'))
        this._selectionsDigitalChampionDomainSource.next(selections.filter(selection => selection?.type === 'DIGITAL_CHAMPION_DOMAIN'))
        this._selectionsSiteStandardStatusSource.next(selections.filter(selection => selection?.type === 'STATUS'))
        this._selectionsSubBusinessUnitContactRoleSource.next(selections.filter(selection => selection?.type === 'SBU_CONTACT_ROLE'))

      }))
      .toPromise()
  }
  async create(selection: CreateSelectionInput) {
    this.loaderService.addLoader('selection', 'create');
    const REQ = gql`
      mutation createSelection($input: CreateSelectionInput!) {
        createSelection(input: $input)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          input: selection
        }
      })
      .toPromise();
    this.loaderService.removeLoader('selection', 'create');
  }
  async update(selection: UpdateSelectionInput) {
    this.loaderService.addLoader('selection', 'update');
    const REQ = gql`
      mutation updateSelection($input: UpdateSelectionInput!) {
        updateSelection(input: $input)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          input: selection
        }
      })
      .toPromise();
    this.loaderService.removeLoader('selection', 'update');
  }
  async delete(selections: Selection[]) {
    this.loaderService.addLoader('selection', 'delete');
    const REQ = gql`
      mutation deleteSelections($selectionIds: [ID!]) {
        deleteSelections(selectionIds: $selectionIds)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          selectionIds: selections.map((selection) => selection.id)
        }
      })
      .toPromise();
    this.loaderService.removeLoader('selection', 'delete');
  }
}
