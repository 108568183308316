<mat-form-field class="input2">
  <input
    [formControl]="searchPlacesCtrl"
    [matAutocomplete]="auto"
    matInput
    placeholder="{{ 'site.address' | translate }}"
  />
  <mat-autocomplete
    [panelWidth]="300"
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="result($event)"
  >
    <mat-option *ngIf="isLoading && searchPlacesCtrl.value.length > 4">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-option>
    <ng-container *ngIf="!isLoading && searchPlacesCtrl.value.length > 4">
      <mat-option *ngFor="let place of filteredPlaces" [value]="place">
        <span>{{ place.address }}</span>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
