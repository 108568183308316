import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, merge, startWith, Subject, switchMap, takeUntil, timer } from 'rxjs';
import { SiteDialogOrganisationComponent } from 'src/app/modules/site-management/components/site-dialog-organisation/site-dialog-organisation.component';
import { LoaderService } from '../..';

@Component({
  selector: 'app-table',
  styleUrls: ['table.component.scss'],
  templateUrl: 'table.component.html'
})
export class TableComponent implements OnChanges, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() list: any[] | null;
  @Input() models: {
    attribute: string;
    label: string;
    type: string;
    postlink?: string;
    prelink?: string;
    icon?: string;
    counter?: string;
    display?: string;
    size?: string;
    function?: Function;
  }[];
  @Input() select = false;
  @Input() multiSelect = false;
  @Input() icon: string;
  @Input() link: string;
  @Input() attributeFilters = false;
  @Input() externalFilterConfig: {
    columns: string[];
    text: string;
    categories: {
      attribute: string;
      value: string;
    }[];
  };

  @Input() multiSelectActive = false;
  @Input() popUpComponent = null;

  @Output() filtered = new EventEmitter<any[]>();

  displayedColumns: string[] = [];
  filteredColumns: string[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  selection = new SelectionModel<any>(true, []);
  resultsLength = 0;
  isLoadingResults = true;
  trigger = new BehaviorSubject<number>(null);

  private destroy$ = new Subject<void>()

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public loaderService: LoaderService,
  ) { }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['list']) {
      this.trigger.next(moment().valueOf())
    }

    if (changes['models']) {
      this.changeColumns();
    }

    if (changes['externalFilterConfig']) {
      if (this.externalFilterConfig) {
        this.selection.clear()
        this.dataSource?.paginator?.firstPage();
        this.trigger.next(moment().valueOf())
      }
    }

    if (changes['multiSelectActive']) {
      this.selection.clear();
      this.changeColumns();
    }
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.trigger).pipe(takeUntil(this.destroy$), startWith({}), switchMap(() => {
      this.isLoadingResults = true;

      //FILTER
      let filteredData = this.list.filter(element => {
    
        let categoriesCheck = []

        // Init
        for (const category of this.externalFilterConfig.categories) {
          categoriesCheck[category.attribute] = false
        }
  
        // Check
        for (const category of this.externalFilterConfig.categories) {
          if (
            !(
            category.attribute &&
            category.value &&
            !(_.get(element, category.attribute)?.toLowerCase() === category.value?.toLowerCase())
          )) {
            categoriesCheck[category.attribute] = true
          }
        }
  
        // Validate
        for (let key in categoriesCheck) {
          if (!categoriesCheck[key]) return false;
        }

        if (this.externalFilterConfig.text) {
          let textToSearch = '';
          this.externalFilterConfig.columns.forEach((column) => {
            textToSearch += _.get(element, column)?.toLowerCase() || '';
          });
          return textToSearch.indexOf(this.externalFilterConfig.text.toLowerCase()) !== -1;
        }
        else {
          return true;
        }
      })

      this.filtered.emit(filteredData);
      this.resultsLength = filteredData.length;

      //PAGINATE
      let paginatedData = _.chain(filteredData).orderBy(this.sort.active, this.sort.direction === 'asc' ? 'asc' : 'desc').splice(this.paginator.pageIndex * this.paginator.pageSize, (this.paginator.pageIndex + 1) * this.paginator.pageSize).value()

      this.dataSource.data = paginatedData
      this.isLoadingResults = false;

      return paginatedData
    })
    ).subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeColumns() {
    this.displayedColumns = this.models.map((element) => element.attribute);
    if (this.icon) this.displayedColumns = ['icon', ...this.displayedColumns];
    if (this.multiSelectActive) this.displayedColumns = [...this.displayedColumns, 'select'];
    this.filteredColumns = this.displayedColumns.map((element) => 'filter-' + element);
  }
  
  //MULTISELECT
  switchMultiSelect() {
    this.multiSelectActive = !this.multiSelectActive;
    this.selection.clear();
    this.changeColumns();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  //OTHERS
  getValue(row: any, attribute: string) {
    return _.get(row, attribute);
  }

  navigateTo(row: any) {
    this.router.navigate([this.link + '/' + row.id]);
  }

  popUpDialog(row: any) {
    const dialogRef = this.dialog.open(this.popUpComponent, {
      width: '550px',
      data: row
    });
  }

  navigateButtonTo(model: any, row: any, attribute?: string) {
    if (attribute) {
      this.router.navigate([model.prelink + _.get(row, attribute).id + (model.postlink || '')]);
    }
    else {
      this.router.navigate([model.prelink + row.id + (model.postlink || '')]);
    }
  }
}
