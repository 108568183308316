import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { CreateSiteInput, Site, LoaderService, UpdateSiteInput, AuthService } from 'src/app/modules/shared';
import { map } from 'rxjs/operators';
import { SignalrService } from './signalr.service';
import * as _ from 'lodash';
import * as omitDeep from 'omit-deep-lodash'
import { set } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  neededContacts = ['managingDirector', 'cfo', 'siteManager', 'itManager', 'automationManager', 'webAppContacts', 'industrialContacts', 'sgtsSecurityOfficer', 'sgtsBusinessRelationManager', 'digitalChampions']
  multipleContact = ['webAppContacts', 'industrialContacts', 'digitalChampions']
  siteEditors = ['managingDirector', 'cfo', 'siteManager', 'itManager', 'automationManager', 'webAppContacts', 'industrialContacts', 'sgtsSecurityOfficer', 'sgtsBusinessRelationManager', 'digitalChampions']
  
  private readonly _sitesSource = new BehaviorSubject<Site[]>([]);
  readonly listSites$ = this._sitesSource.asObservable();

  constructor(
    private apollo: Apollo,
    private signalrService: SignalrService,
    private loaderService: LoaderService,
    private authService: AuthService,
  ) {
    this.refresh();
    //this.getUser()
    this.signalrService.connection.on('sites', (element) => {
      this.refresh();
    });
    this.authService.token$.subscribe(() => {
      this.refresh()
    })
  }

  getValue() {
    return this._sitesSource.getValue();
  }

  getSite(siteID) {
    return this._sitesSource.getValue().filter(elm => elm.id === siteID)[0];
  }

  async refresh() {
    this.loaderService.addLoader('site', 'list');
    const REQ = gql`
      query listSitesCache {
        listSitesCache
      }
    `;
    this._sitesSource.next(
      await this.apollo
        .query<any>({
          query: REQ,
          variables: {}
        })
        .pipe(map((result) => {
          this.loaderService.removeLoader('site', 'list');
          return _.sortBy(result?.data?.listSitesCache || [], 'title')
        }))
        .toPromise()
    );
  }


  async getUser() {
    this.loaderService.addLoader('site', 'user');
    const REQ = gql`
      query getUser {
        getUser
      }
    `;
    console.log(
      await this.apollo
        .query<any>({
          query: REQ,
          variables: {}
        })
        .pipe(map((result) => {
          this.loaderService.removeLoader('site', 'user');
          return result?.data?.getUser
        }))
        .toPromise()
    );
  }

  async create(site: CreateSiteInput, broadcast: boolean) {
    this.loaderService.addLoader('site', 'create');
    const REQ = gql`
      mutation createSite($input: CreateSiteInput!,$broadcast:Boolean!) {
        createSite(input: $input, broadcast: $broadcast)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          input: site,
          broadcast: broadcast
        }
      })
      .toPromise();
    this.loaderService.removeLoader('site', 'create');
  }

  async update(site: UpdateSiteInput) {
    this.loaderService.addLoader('site', 'update');
    const REQ = gql`
      mutation updateSite($input: UpdateSiteInput!) {
        updateSite(input: $input)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          input: this.cleanData(site)
        }
      })
      .toPromise();
    this.loaderService.removeLoader('site', 'update');
  }

  async delete(sites: Site[]) {
    this.loaderService.addLoader('site', 'delete');
    const REQ = gql`
      mutation deleteSites($siteIds: [ID!]) {
        deleteSites(siteIds: $siteIds)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          siteIds: sites.map((site) => site.id)
        }
      })
      .toPromise();
    this.loaderService.removeLoader('site', 'delete');
  }

  cleanData(item) {
    return omitDeep(item, '_ts', '_self', '_attachments', '_etag', '_rid', '__typename')
  }
}
