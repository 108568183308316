import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';
import HighchartsBoost from 'highcharts/modules/boost';
import Xrange from 'highcharts/modules/xrange';
//import Exporting from 'highcharts/modules/exporting';
import Drilldown from 'highcharts/modules/drilldown';
//import highcharts3D from 'highcharts/highcharts-3d';
import * as FileSaver from 'file-saver';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import * as moment from 'moment';

//Exporting(Highcharts);

//highcharts3D(Highcharts);
Xrange(Highcharts);
Drilldown(Highcharts);
HighchartsBoost(Highcharts);

const timezone = new Date().getTimezoneOffset();

Highcharts.setOptions({
  time: {
    timezoneOffset: timezone
  }
});

@Injectable({
  providedIn: 'root'
})
export class ChartService {
  constructor(public http: HttpClient) { }

  public Highcharts: typeof Highcharts = Highcharts;

  getPieChart(series: any) {
    return {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      credits: {
        enabled: false
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        }
      },
      title: {
        text: ''
      },
      series: [
        {
          name: '',
          colorByPoint: true,
          data: series
        }
      ]
    };
  }
  getBarChart(series: any) {
    return {
      chart: {
        type: 'bar'
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: series.map((serie) => serie.name),
        title: {
          text: null
        }
      },
      series: [
        {
          name: '',
          colorByPoint: true,
          data: series
        }
      ]
    };
  }
  getLineBarChart(type: string, series: any[], linesx: any[], linesy: any[], band: number) {
    let color: string[] = [];
    if (Highcharts.getOptions()?.colors?.length) {
      color = Highcharts.getOptions().colors || [];
    }
    let plotBand: any[] = [];
    if (band) {
      plotBand = [
        {
          from: band ? band * (type !== 'spline' ? 0.9 : 0.95) : 0,
          to: band ? band * (type !== 'spline' ? 1.1 : 1.05) : 0,
          color: 'rgba(68, 170, 213, 0.1)',
          label: {
            text: 'Expected cycle time',
            style: {
              color: '#606060'
            }
          }
        }
      ];
    }
    return {
      credits: {
        enabled: false
      },
      chart: {
        zoomType: 'x',
        type: type
      },
      plotOptions: {
        spline: {
          marker: {
            enable: false
          }
        },
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, color[0]],
              [1, Highcharts.color(color[0]).setOpacity(0).get('rgba')]
            ]
          },
          marker: {
            radius: 2
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          threshold: null
        }
      },
      title: {
        text: ''
      },
      tooltip: {
        valueDecimals: 2
      },
      xAxis: {
        ordinal: true,
        crosshair: type === 'spline',
        type: 'datetime',
        plotLines: linesx?.map((line) => ({
          color: '#FF0000',
          width: 1,
          value: line,
          dashStyle: 'dash'
        }))
      },
      yAxis: {
        crosshair: type === 'spline',
        type: 'linear',
        plotLines: linesy?.map((line) => ({
          color: '#FF0000',
          width: 2,
          value: line,
          dashStyle: 'dash'
        })),
        plotBands: plotBand
      },
      series: series
    };
  }
  getBarDrillChart(serie: any, level: string) {
    let drilldown: any;
    if (level === 'day') {
      drilldown = this.generateDrilldownDay(serie);
    }
    if (level === 'month') {
      drilldown = this.generateDrilldownMonth(serie);
    }
    return {
      chart: {
        type: 'column',
        marginTop: 50
      },
      title: {
        text: ''
      },
      subtitle: {
        text: 'Click the day to show stats by hour'
      },
      accessibility: {
        announceNewData: {
          enabled: true
        }
      },
      xAxis: {
        type: 'category'
      },
      yAxis: {
        title: {
          text: ''
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y}'
          }
        }
      },

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}Parts</b><br/>'
      },

      series: drilldown.series,
      drilldown: drilldown.drilldown
    };
  }
  export(options: any, filename: string) {
    const data = {
      options: JSON.stringify(options),
      filename: 'hello',
      type: 'image/png',
      width: 2000,
      async: false
    };
    const exportUrl = 'https://export.highcharts.com/';
    const headers = { 'content-type': 'application/json' };
    this.http
      .post(exportUrl, data, { headers: headers, responseType: 'blob' as 'json' })
      .subscribe((response: any) => {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const buffer = new Blob(binaryData, { type: dataType });
        FileSaver.saveAs(buffer, filename);
      });
  }

  generateDrilldownMonth(serie: any[]) {
    const groupedByMonth = _.groupBy(serie, function (item) {
      return moment(item[0]).format('MMMM YYYY');
    });
    const series: any[] = [];
    const drilldown: any[] = [];

    Object.entries(groupedByMonth).forEach(([keymonth, value]) => {
      const sumMonth = _.sumBy(value, function (item) {
        return item[1];
      });
      series.push({
        name: keymonth,
        colorByPoint: true,
        y: sumMonth,
        drilldown: keymonth
      });
      const groupedByDay = _.groupBy(value, function (item) {
        return moment(item[0]).format('dddd DD');
      });
      const drilldownday: any[] = [];
      Object.entries(groupedByDay).forEach(([keyday, value]) => {
        const sumDay = _.sumBy(value, function (item) {
          return item[1];
        });
        const groupedByHour = _.groupBy(value, function (item) {
          return moment(item[0]).format('HH');
        });
        const drilldownhour: any[] = [];
        Object.entries(groupedByHour).forEach(([keyhour, value]) => {
          const sumHour = _.sumBy(value, function (item) {
            return item[1];
          });
          drilldownhour.push([keyhour + 'h', sumHour]);
        });
        drilldownday.push({
          name: keyday,
          colorByPoint: true,
          y: sumDay,
          drilldown: keyday
        });
        drilldown.push({
          name: keyday,
          colorByPoint: true,
          id: keyday,
          data: drilldownhour
        });
      });
      drilldown.push({
        name: keymonth,
        colorByPoint: true,
        id: keymonth,
        data: drilldownday
      });
    });
    return {
      series: [
        {
          name: 'All Months',
          colorByPoint: true,
          data: series
        }
      ],
      drilldown: {
        series: drilldown,
        drillUpButton: {
          position: {
            verticalAlign: 'top',
            align: 'rigth',
            x: -0,
            y: -50
          }
        }
      }
    };
  }

  generateDrilldownDay(serie: any[]) {
    const groupedByDay = _.groupBy(serie, function (item) {
      return moment(item[0]).format('DD MMMM YYYY');
    });
    const series: any[] = [];
    const drilldown: any[] = [];

    Object.entries(groupedByDay).forEach(([keyday, value]) => {
      const sumDay = _.sumBy(value, function (item) {
        return item[1];
      });
      series.push({
        name: keyday,
        colorByPoint: true,
        y: sumDay,
        drilldown: keyday
      });
      const groupedByHour = _.groupBy(value, function (item) {
        return moment(item[0]).format('HH');
      });
      const drilldownHour: any[] = [];
      Object.entries(groupedByHour).forEach(([keyhour, value]) => {
        const sumHour = _.sumBy(value, function (item) {
          return item[1];
        });
        drilldownHour.push({
          name: keyhour + 'h',
          y: sumHour,
          drilldown: keyhour
        });
      });
      drilldown.push({
        name: keyday,
        colorByPoint: true,
        id: keyday,
        data: drilldownHour
      });
    });
    return {
      series: [
        {
          name: 'All Days',
          colorByPoint: true,
          data: series
        }
      ],
      drilldown: {
        series: drilldown,
        drillUpButton: {
          position: {
            verticalAlign: 'top',
            align: 'rigth',
            x: -0,
            y: -50
          }
        }
      }
    };
  }
}
