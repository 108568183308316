<div fxLayout="row wrap">
  <app-search-people-digital-champion [(selected)]="current" [formName]="formName"></app-search-people-digital-champion>
  <button mat-icon-button (click)="addItem()"><mat-icon class="block-space">add</mat-icon></button>
  <div class="scrolling-wrapper">
      <span *ngFor="let item of selected" class="card">
        {{ item.mail }}
        <button mat-icon-button (click)="deleteItem(item)">
          <mat-icon>delete</mat-icon>
        </button>
      </span>
  </div>
</div>