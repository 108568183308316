import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  JSON: any;
  JSONObject: any;
  Upload: any;
};

export type BusinessUnit = {
  __typename?: 'BusinessUnit';
  id: Scalars['ID'];
  title: Scalars['String'];
  cio?: Maybe<Contact>;
};

export type Contact = {
  __typename?: 'Contact';
  id?: Maybe<Scalars['ID']>;
  userPrincipalName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  mail?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  onSite?: Maybe<Scalars['Boolean']>;
  isTechnical?: Maybe<Scalars['Boolean']>;
  firstContact?: Maybe<Scalars['Boolean']>;
  viewer?: Maybe<Scalars['Boolean']>;
  editor?: Maybe<Array<Maybe<Scalars['String']>>>;
  validator?: Maybe<Array<Maybe<Scalars['String']>>>;
  globalAdmin?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Selection>;
};

export type CreateBusinessUnitInput = {
  title: Scalars['String'];
  cio?: Maybe<UpdateContactInput>;
};

export type CreateContactInput = {
  id?: Maybe<Scalars['ID']>;
  userPrincipalName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  mail?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  onSite?: Maybe<Scalars['Boolean']>;
  isTechnical?: Maybe<Scalars['Boolean']>;
  firstContact?: Maybe<Scalars['Boolean']>;
  viewer?: Maybe<Scalars['Boolean']>;
  editor?: Maybe<Array<Maybe<Scalars['String']>>>;
  validator?: Maybe<Array<Maybe<Scalars['String']>>>;
  globalAdmin?: Maybe<Scalars['Boolean']>;
  role?: Maybe<UpdateSelectionInput>;
};

export type CreateFeatureInput = {
  key: Scalars['String'];
};

export type CreateGroupInput = {
  title: Scalars['String'];
  featureIds?: Maybe<Array<Scalars['String']>>;
};

export type CreateMagnitudeInput = {
  id?: Maybe<Scalars['ID']>;
  SRU_Code?: Maybe<Scalars['String']>;
  SRU_Name?: Maybe<Scalars['String']>;
  Entity_Code?: Maybe<Scalars['String']>;
  Entity?: Maybe<Scalars['String']>;
  Segment_Code?: Maybe<Scalars['String']>;
  Segment?: Maybe<Scalars['String']>;
  Business_group_code?: Maybe<Scalars['String']>;
  Business_group?: Maybe<Scalars['String']>;
  Branche_Code?: Maybe<Scalars['String']>;
  Branche?: Maybe<Scalars['String']>;
};

export type CreateSelectionInput = {
  type: SelectionType;
  code: Scalars['String'];
  title: Scalars['String'];
  color: Scalars['JSON'];
};

export type CreateSiteInput = {
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  hpsCode?: Maybe<Scalars['String']>;
  sgtCode?: Maybe<Scalars['String']>;
  sruCode?: Maybe<Scalars['String']>;
  shared?: Maybe<UpdateSelectionInput>;
  sharedId?: Maybe<Scalars['ID']>;
  activity?: Maybe<UpdateSelectionInput>;
  activityId?: Maybe<Scalars['ID']>;
  displayLoc?: Maybe<UpdateSelectionInput>;
  displayLocId?: Maybe<Scalars['ID']>;
  employeeCount?: Maybe<UpdateSelectionInput>;
  employeeCountId?: Maybe<Scalars['ID']>;
  itUserCount?: Maybe<UpdateSelectionInput>;
  itUserCountId?: Maybe<Scalars['ID']>;
  usualName?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  localisation?: Maybe<LocalisationInput>;
  subBusinessUnitId: Scalars['ID'];
  subBusinessUnit?: Maybe<Scalars['JSON']>;
  regionId?: Maybe<Scalars['ID']>;
  region?: Maybe<UpdateSelectionInput>;
  managingDirector?: Maybe<UpdateContactInput>;
  cfo?: Maybe<UpdateContactInput>;
  siteManager?: Maybe<UpdateContactInput>;
  itManager?: Maybe<UpdateContactInput>;
  automationManager?: Maybe<UpdateContactInput>;
  webAppContacts?: Maybe<Array<Maybe<UpdateContactInput>>>;
  industrialContacts?: Maybe<Array<Maybe<UpdateContactInput>>>;
  digitalChampions?: Maybe<Array<Maybe<UpdateContactInput>>>;
  sgtsAssignement?: Maybe<UpdateSelectionInput>;
  sgtsAssignementId?: Maybe<Scalars['ID']>;
  sgtsSecurityOfficer?: Maybe<UpdateContactInput>;
  sgtsBusinessRelationManager?: Maybe<UpdateContactInput>;
  impact?: Maybe<UpdateSelectionInput>;
  impactId?: Maybe<Scalars['ID']>;
  functionality?: Maybe<UpdateSelectionInput>;
  functionalityId?: Maybe<Scalars['ID']>;
  connectivity?: Maybe<UpdateSelectionInput>;
  connectivityId?: Maybe<Scalars['ID']>;
  accessibility?: Maybe<UpdateSelectionInput>;
  accessibilityId?: Maybe<Scalars['ID']>;
  classification?: Maybe<UpdateSelectionInput>;
  classificationId?: Maybe<Scalars['ID']>;
  bcpCoveringOutage?: Maybe<UpdateSelectionInput>;
  bcpCoveringOutageId?: Maybe<Scalars['ID']>;
  industrialNetwork?: Maybe<UpdateSelectionInput>;
  industrialNetworkId?: Maybe<Scalars['ID']>;
  paloAlto?: Maybe<UpdateSelectionInput>;
  paloAltoId?: Maybe<Scalars['ID']>;
  iDMZ?: Maybe<UpdateSelectionInput>;
  iDMZId?: Maybe<Scalars['ID']>;
  networkSegmentationIndustrial?: Maybe<UpdateSelectionInput>;
  networkSegmentationIndustrialId?: Maybe<Scalars['ID']>;
  cyberX?: Maybe<UpdateSelectionInput>;
  cyberXId?: Maybe<Scalars['ID']>;
  wifi3?: Maybe<UpdateSelectionInput>;
  wifi3Id?: Maybe<Scalars['ID']>;
  comment?: Maybe<Scalars['String']>;
  lastValidatorEmail?: Maybe<Scalars['String']>;
  lastValidationDate?: Maybe<Scalars['Float']>;
  lastAttributeUpdated?: Maybe<Scalars['String']>;
  magnitude?: Maybe<UpdateMagnitudeInput>;
  magnitudeId?: Maybe<Scalars['ID']>;
  smartR?: Maybe<UpdateSmartRInput>;
  smartRId?: Maybe<Scalars['ID']>;
  active?: Maybe<UpdateSelectionInput>;
  activeId?: Maybe<Scalars['ID']>;
  closeDate?: Maybe<Scalars['Float']>;
  groupReferentialCode?: Maybe<Scalars['String']>;
  smartRCode?: Maybe<Scalars['String']>;
  editors?: Maybe<Array<Maybe<Scalars['String']>>>;
  reviewers?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Scalars['String']>;
};

export type CreateSmartRInput = {
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
  sruCode?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  street3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type CreateSubBusinessUnitContactInput = {
  subBusinessUnitId: Scalars['ID'];
  scopeId: Scalars['ID'];
  user?: Maybe<UpdateContactInput>;
  roleId: Scalars['ID'];
};

export type CreateSubBusinessUnitInput = {
  businessUnitId: Scalars['ID'];
  title: Scalars['String'];
  isitManager?: Maybe<UpdateContactInput>;
};

export type CreateTranslationInput = {
  language: Scalars['String'];
  client: Scalars['String'];
  category: Scalars['String'];
  subCategory: Scalars['String'];
  key: Scalars['String'];
  value: Scalars['String'];
};

export type CreateUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  attribute?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['JSON']>;
  requesterEmail?: Maybe<Scalars['String']>;
  requestDate?: Maybe<Scalars['Float']>;
  siteID?: Maybe<Scalars['String']>;
  subBusinessUnitID?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type CreateUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  nickName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  language: Scalars['String'];
  mobile?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Feature = {
  __typename?: 'Feature';
  id: Scalars['ID'];
  key: Scalars['String'];
};

export type Group = {
  __typename?: 'Group';
  id: Scalars['ID'];
  title: Scalars['String'];
  features?: Maybe<Array<Feature>>;
  members?: Maybe<Array<User>>;
};



export type Localisation = {
  __typename?: 'Localisation';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  postalCode?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  utcOffset?: Maybe<Scalars['Int']>;
};

export type LocalisationInput = {
  placeId?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  utcOffset?: Maybe<Scalars['Int']>;
  postalCode?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  user: User;
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  expiresIn: Scalars['Int'];
  expiresAt: Scalars['Float'];
  featuresToken: Scalars['String'];
};

export type LoginUserInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Magnitude = {
  __typename?: 'Magnitude';
  id?: Maybe<Scalars['ID']>;
  SRU_Code?: Maybe<Scalars['String']>;
  SRU_Name?: Maybe<Scalars['String']>;
  Entity_Code?: Maybe<Scalars['String']>;
  Entity?: Maybe<Scalars['String']>;
  Segment_Code?: Maybe<Scalars['String']>;
  Segment?: Maybe<Scalars['String']>;
  Business_group_code?: Maybe<Scalars['String']>;
  Business_group?: Maybe<Scalars['String']>;
  Branche_Code?: Maybe<Scalars['String']>;
  Branche?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addGroupMember?: Maybe<Scalars['Boolean']>;
  createBusinessUnit?: Maybe<Scalars['ID']>;
  createContact?: Maybe<Scalars['Boolean']>;
  createFeature?: Maybe<Scalars['Boolean']>;
  createGroup?: Maybe<Scalars['Boolean']>;
  createMagnitude?: Maybe<Scalars['Boolean']>;
  createSelection?: Maybe<Scalars['ID']>;
  createSite?: Maybe<Scalars['Boolean']>;
  createSmartR?: Maybe<Scalars['Boolean']>;
  createSubBusinessUnit?: Maybe<Scalars['ID']>;
  createSubBusinessUnitContact?: Maybe<Scalars['ID']>;
  createTranslation?: Maybe<Scalars['Boolean']>;
  createUpdate?: Maybe<Scalars['Boolean']>;
  createUser?: Maybe<Scalars['Boolean']>;
  deleteBusinessUnits?: Maybe<Scalars['Boolean']>;
  deleteContacts?: Maybe<Scalars['Boolean']>;
  deleteFeatures?: Maybe<Scalars['Boolean']>;
  deleteGroups?: Maybe<Scalars['Boolean']>;
  deleteMagnitudes?: Maybe<Scalars['Boolean']>;
  deleteSelections?: Maybe<Scalars['Boolean']>;
  deleteSites?: Maybe<Scalars['Boolean']>;
  deleteSmartRs?: Maybe<Scalars['Boolean']>;
  deleteSubBusinessUnitContacts?: Maybe<Scalars['Boolean']>;
  deleteSubBusinessUnits?: Maybe<Scalars['Boolean']>;
  deleteTranslation?: Maybe<Scalars['Boolean']>;
  deleteUpdates?: Maybe<Scalars['Boolean']>;
  deleteUsers?: Maybe<Scalars['Boolean']>;
  disableUser?: Maybe<Scalars['Boolean']>;
  enableUser?: Maybe<Scalars['Boolean']>;
  forgotPassword?: Maybe<Scalars['Boolean']>;
  removeGroupMember?: Maybe<Scalars['Boolean']>;
  resetPassword?: Maybe<Scalars['Boolean']>;
  unlockUser?: Maybe<Scalars['Boolean']>;
  updateBusinessUnit?: Maybe<Scalars['Boolean']>;
  updateContact?: Maybe<Scalars['Boolean']>;
  updateFeature?: Maybe<Scalars['Boolean']>;
  updateGroup?: Maybe<Scalars['Boolean']>;
  updateMagnitude?: Maybe<Scalars['Boolean']>;
  updateSelection?: Maybe<Scalars['Boolean']>;
  updateSite?: Maybe<Scalars['Boolean']>;
  updateSmartR?: Maybe<Scalars['Boolean']>;
  updateSubBusinessUnit?: Maybe<Scalars['Boolean']>;
  updateSubBusinessUnitContact?: Maybe<Scalars['Boolean']>;
  updateTranslation?: Maybe<Scalars['Boolean']>;
  updateUpdate?: Maybe<Scalars['Boolean']>;
  updateUser?: Maybe<Scalars['Boolean']>;
};


export type MutationAddGroupMemberArgs = {
  groupIds?: Maybe<Array<Scalars['ID']>>;
  userIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBusinessUnitArgs = {
  input: CreateBusinessUnitInput;
  broadcast: Scalars['Boolean'];
};


export type MutationCreateContactArgs = {
  input: CreateContactInput;
  broadcast: Scalars['Boolean'];
};


export type MutationCreateFeatureArgs = {
  input: CreateFeatureInput;
};


export type MutationCreateGroupArgs = {
  input?: Maybe<CreateGroupInput>;
};


export type MutationCreateMagnitudeArgs = {
  input: CreateMagnitudeInput;
};


export type MutationCreateSelectionArgs = {
  input: CreateSelectionInput;
};


export type MutationCreateSiteArgs = {
  input: CreateSiteInput;
  broadcast: Scalars['Boolean'];
};


export type MutationCreateSmartRArgs = {
  input: CreateSmartRInput;
  broadcast: Scalars['Boolean'];
};


export type MutationCreateSubBusinessUnitArgs = {
  input: CreateSubBusinessUnitInput;
  broadcast: Scalars['Boolean'];
};


export type MutationCreateSubBusinessUnitContactArgs = {
  input: CreateSubBusinessUnitContactInput;
  broadcast: Scalars['Boolean'];
};


export type MutationCreateTranslationArgs = {
  input: CreateTranslationInput;
};


export type MutationCreateUpdateArgs = {
  input: CreateUpdateInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteBusinessUnitsArgs = {
  businessUnitIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationDeleteContactsArgs = {
  contactIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationDeleteFeaturesArgs = {
  featureIds: Array<Scalars['ID']>;
};


export type MutationDeleteGroupsArgs = {
  groupIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationDeleteMagnitudesArgs = {
  magnitudeIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationDeleteSelectionsArgs = {
  selectionIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationDeleteSitesArgs = {
  siteIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationDeleteSmartRsArgs = {
  smartRIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationDeleteSubBusinessUnitContactsArgs = {
  subBusinessUnitContactIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationDeleteSubBusinessUnitsArgs = {
  subBusinessUnitIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationDeleteTranslationArgs = {
  id: Scalars['String'];
};


export type MutationDeleteUpdatesArgs = {
  updateIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationDeleteUsersArgs = {
  userIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationDisableUserArgs = {
  email: Scalars['String'];
};


export type MutationEnableUserArgs = {
  email: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationRemoveGroupMemberArgs = {
  groupIds?: Maybe<Array<Scalars['ID']>>;
  userIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationResetPasswordArgs = {
  input: LoginUserInput;
};


export type MutationUnlockUserArgs = {
  email: Scalars['String'];
};


export type MutationUpdateBusinessUnitArgs = {
  input: UpdateBusinessUnitInput;
};


export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};


export type MutationUpdateFeatureArgs = {
  input: UpdateFeatureInput;
};


export type MutationUpdateGroupArgs = {
  input?: Maybe<UpdateGroupInput>;
};


export type MutationUpdateMagnitudeArgs = {
  input: UpdateMagnitudeInput;
};


export type MutationUpdateSelectionArgs = {
  input: UpdateSelectionInput;
};


export type MutationUpdateSiteArgs = {
  input: UpdateSiteInput;
};


export type MutationUpdateSmartRArgs = {
  input: UpdateSmartRInput;
};


export type MutationUpdateSubBusinessUnitArgs = {
  input: UpdateSubBusinessUnitInput;
};


export type MutationUpdateSubBusinessUnitContactArgs = {
  input: UpdateSubBusinessUnitContactInput;
};


export type MutationUpdateTranslationArgs = {
  input: UpdateTranslationInput;
};


export type MutationUpdateUpdateArgs = {
  input: UpdateUpdateInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type PlaceData = {
  __typename?: 'PlaceData';
  placeId?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  utcOffset?: Maybe<Scalars['Int']>;
  postalCode?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  checkEmail?: Maybe<Scalars['Boolean']>;
  convertAddress?: Maybe<Localisation>;
  getPlaceFromPlaceID?: Maybe<PlaceData>;
  getUser?: Maybe<Scalars['JSON']>;
  listBusinessUnits: Array<Maybe<BusinessUnit>>;
  listContacts: Array<Maybe<Contact>>;
  listFeatures?: Maybe<Array<Maybe<Feature>>>;
  listGroups: Array<Maybe<Group>>;
  listMagnitudes: Array<Maybe<Magnitude>>;
  listSelections: Array<Maybe<Selection>>;
  listSites: Array<Maybe<Site>>;
  listSitesCache: Array<Maybe<Scalars['JSON']>>;
  listSmartRs: Array<Maybe<SmartR>>;
  listSubBusinessUnitContacts: Array<Maybe<SubBusinessUnitContact>>;
  listSubBusinessUnits: Array<Maybe<SubBusinessUnit>>;
  listTranslations?: Maybe<Array<Maybe<Translation>>>;
  listUpdates: Array<Maybe<Update>>;
  listUsers: Array<Maybe<User>>;
  login?: Maybe<LoginResponse>;
  renew?: Maybe<LoginResponse>;
  resetPasswordCTA?: Maybe<LoginResponse>;
  searchPlace?: Maybe<Array<Maybe<PlaceData>>>;
};


export type QueryCheckEmailArgs = {
  email?: Maybe<Scalars['String']>;
};


export type QueryConvertAddressArgs = {
  address: Scalars['String'];
};


export type QueryGetPlaceFromPlaceIdArgs = {
  placeId: Scalars['String'];
};


export type QueryLoginArgs = {
  input: LoginUserInput;
};


export type QueryRenewArgs = {
  refreshToken?: Maybe<Scalars['String']>;
};


export type QueryResetPasswordCtaArgs = {
  input: ResetPasswordInput;
};


export type QuerySearchPlaceArgs = {
  address: Scalars['String'];
};

export type ResetPasswordInput = {
  password: Scalars['String'];
  code: Scalars['String'];
};

export type Selection = {
  __typename?: 'Selection';
  id: Scalars['ID'];
  type: SelectionType;
  code: Scalars['String'];
  title: Scalars['String'];
  color: Scalars['JSON'];
};

export enum SelectionType {
  Boolean = 'BOOLEAN',
  Operational = 'OPERATIONAL',
  Functionality = 'FUNCTIONALITY',
  Connectivity = 'CONNECTIVITY',
  Accessibility = 'ACCESSIBILITY',
  Attacker = 'ATTACKER',
  Class = 'CLASS',
  Region = 'REGION',
  Activity = 'ACTIVITY',
  DigitalChampionDomain = 'DIGITAL_CHAMPION_DOMAIN',
  Status = 'STATUS',
  SbuContactRole = 'SBU_CONTACT_ROLE',
  PeopleRange = 'PEOPLE_RANGE',
  SgtsAssignement = 'SGTS_ASSIGNEMENT',
  DisplayLoc = 'DISPLAY_LOC'
}

export type Site = {
  __typename?: 'Site';
  id: Scalars['ID'];
  title: Scalars['String'];
  hpsCode?: Maybe<Scalars['String']>;
  sgtCode?: Maybe<Scalars['String']>;
  sruCode?: Maybe<Scalars['String']>;
  shared?: Maybe<Selection>;
  sharedId?: Maybe<Scalars['ID']>;
  activity?: Maybe<Selection>;
  activityId?: Maybe<Scalars['ID']>;
  displayLoc?: Maybe<Selection>;
  displayLocId?: Maybe<Scalars['ID']>;
  employeeCount?: Maybe<Selection>;
  employeeCountId?: Maybe<Scalars['ID']>;
  itUserCount?: Maybe<Selection>;
  itUserCountId?: Maybe<Scalars['ID']>;
  usualName?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  localisation?: Maybe<Localisation>;
  subBusinessUnitId: Scalars['ID'];
  subBusinessUnit?: Maybe<SubBusinessUnit>;
  regionId?: Maybe<Scalars['ID']>;
  region?: Maybe<Selection>;
  managingDirector?: Maybe<Contact>;
  cfo?: Maybe<Contact>;
  siteManager?: Maybe<Contact>;
  itManager?: Maybe<Contact>;
  automationManager?: Maybe<Contact>;
  webAppContacts?: Maybe<Array<Maybe<Contact>>>;
  industrialContacts?: Maybe<Array<Maybe<Contact>>>;
  digitalChampions?: Maybe<Array<Maybe<Contact>>>;
  sgtsAssignement?: Maybe<Selection>;
  sgtsAssignementId?: Maybe<Scalars['ID']>;
  sgtsSecurityOfficer?: Maybe<Contact>;
  sgtsBusinessRelationManager?: Maybe<Contact>;
  impact?: Maybe<Selection>;
  impactId?: Maybe<Scalars['ID']>;
  functionality?: Maybe<Selection>;
  functionalityId?: Maybe<Scalars['ID']>;
  connectivity?: Maybe<Selection>;
  connectivityId?: Maybe<Scalars['ID']>;
  accessibility?: Maybe<Selection>;
  accessibilityId?: Maybe<Scalars['ID']>;
  classification?: Maybe<Selection>;
  classificationId?: Maybe<Scalars['ID']>;
  bcpCoveringOutage?: Maybe<Selection>;
  bcpCoveringOutageId?: Maybe<Scalars['ID']>;
  industrialNetwork?: Maybe<Selection>;
  industrialNetworkId?: Maybe<Scalars['ID']>;
  paloAlto?: Maybe<Selection>;
  paloAltoId?: Maybe<Scalars['ID']>;
  iDMZ?: Maybe<Selection>;
  iDMZId?: Maybe<Scalars['ID']>;
  networkSegmentationIndustrial?: Maybe<Selection>;
  networkSegmentationIndustrialId?: Maybe<Scalars['ID']>;
  cyberX?: Maybe<Selection>;
  cyberXId?: Maybe<Scalars['ID']>;
  wifi3?: Maybe<Selection>;
  wifi3Id?: Maybe<Scalars['ID']>;
  comment?: Maybe<Scalars['String']>;
  lastValidatorEmail?: Maybe<Scalars['String']>;
  lastValidationDate?: Maybe<Scalars['Float']>;
  lastAttributeUpdated?: Maybe<Scalars['String']>;
  magnitude?: Maybe<Magnitude>;
  smartR?: Maybe<SmartR>;
  active?: Maybe<Selection>;
  activeId?: Maybe<Scalars['ID']>;
  closeDate?: Maybe<Scalars['Float']>;
  groupReferentialCode?: Maybe<Scalars['String']>;
  smartRCode?: Maybe<Scalars['String']>;
  editors?: Maybe<Array<Maybe<Scalars['String']>>>;
  reviewers?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Scalars['String']>;
};

export type SmartR = {
  __typename?: 'SmartR';
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
  sruCode?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  street3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type SubBusinessUnit = {
  __typename?: 'SubBusinessUnit';
  id: Scalars['ID'];
  businessUnitId: Scalars['ID'];
  businessUnit?: Maybe<BusinessUnit>;
  title: Scalars['String'];
  isitManager?: Maybe<Contact>;
  contacts?: Maybe<Array<SubBusinessUnitContact>>;
};

export type SubBusinessUnitContact = {
  __typename?: 'SubBusinessUnitContact';
  id: Scalars['ID'];
  subBusinessUnitId: Scalars['ID'];
  subBusinessUnit?: Maybe<SubBusinessUnit>;
  scopeId: Scalars['ID'];
  scope?: Maybe<Selection>;
  user?: Maybe<Contact>;
  roleId: Scalars['ID'];
  role?: Maybe<Selection>;
};

export type Translation = {
  __typename?: 'Translation';
  id?: Maybe<Scalars['ID']>;
  category?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type Update = {
  __typename?: 'Update';
  id?: Maybe<Scalars['ID']>;
  attribute?: Maybe<Scalars['String']>;
  lastValue?: Maybe<Scalars['JSON']>;
  value?: Maybe<Scalars['JSON']>;
  requesterEmail?: Maybe<Scalars['String']>;
  requestDate?: Maybe<Scalars['Float']>;
  siteID?: Maybe<Scalars['String']>;
  subBusinessUnitID?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type UpdateBusinessUnitInput = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  cio?: Maybe<UpdateContactInput>;
};

export type UpdateContactInput = {
  id?: Maybe<Scalars['ID']>;
  userPrincipalName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  mail?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  onSite?: Maybe<Scalars['Boolean']>;
  isTechnical?: Maybe<Scalars['Boolean']>;
  firstContact?: Maybe<Scalars['Boolean']>;
  viewer?: Maybe<Scalars['Boolean']>;
  editor?: Maybe<Array<Maybe<Scalars['String']>>>;
  validator?: Maybe<Array<Maybe<Scalars['String']>>>;
  globalAdmin?: Maybe<Scalars['Boolean']>;
  role?: Maybe<UpdateSelectionInput>;
};

export type UpdateFeatureInput = {
  id?: Maybe<Scalars['ID']>;
  key: Scalars['String'];
};

export type UpdateGroupInput = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  featureIds?: Maybe<Array<Scalars['String']>>;
};

export type UpdateMagnitudeInput = {
  id?: Maybe<Scalars['ID']>;
  SRU_Code?: Maybe<Scalars['String']>;
  SRU_Name?: Maybe<Scalars['String']>;
  Entity_Code?: Maybe<Scalars['String']>;
  Entity?: Maybe<Scalars['String']>;
  Segment_Code?: Maybe<Scalars['String']>;
  Segment?: Maybe<Scalars['String']>;
  Business_group_code?: Maybe<Scalars['String']>;
  Business_group?: Maybe<Scalars['String']>;
  Branche_Code?: Maybe<Scalars['String']>;
  Branche?: Maybe<Scalars['String']>;
};

export type UpdateSelectionInput = {
  id: Scalars['ID'];
  type?: Maybe<SelectionType>;
  code?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['JSON']>;
};

export type UpdateSiteInput = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  hpsCode?: Maybe<Scalars['String']>;
  sgtCode?: Maybe<Scalars['String']>;
  sruCode?: Maybe<Scalars['String']>;
  shared?: Maybe<UpdateSelectionInput>;
  sharedId?: Maybe<Scalars['ID']>;
  activity?: Maybe<UpdateSelectionInput>;
  activityId?: Maybe<Scalars['ID']>;
  displayLoc?: Maybe<UpdateSelectionInput>;
  displayLocId?: Maybe<Scalars['ID']>;
  employeeCount?: Maybe<UpdateSelectionInput>;
  employeeCountId?: Maybe<Scalars['ID']>;
  itUserCount?: Maybe<UpdateSelectionInput>;
  itUserCountId?: Maybe<Scalars['ID']>;
  usualName?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  localisation?: Maybe<LocalisationInput>;
  subBusinessUnitId?: Maybe<Scalars['ID']>;
  subBusinessUnit?: Maybe<Scalars['JSON']>;
  businessUnit?: Maybe<Scalars['JSON']>;
  regionId?: Maybe<Scalars['ID']>;
  region?: Maybe<UpdateSelectionInput>;
  managingDirector?: Maybe<UpdateContactInput>;
  cfo?: Maybe<UpdateContactInput>;
  siteManager?: Maybe<UpdateContactInput>;
  itManager?: Maybe<UpdateContactInput>;
  automationManager?: Maybe<UpdateContactInput>;
  webAppContacts?: Maybe<Array<Maybe<UpdateContactInput>>>;
  industrialContacts?: Maybe<Array<Maybe<UpdateContactInput>>>;
  digitalChampions?: Maybe<Array<Maybe<UpdateContactInput>>>;
  sgtsAssignement?: Maybe<UpdateSelectionInput>;
  sgtsAssignementId?: Maybe<Scalars['ID']>;
  sgtsSecurityOfficer?: Maybe<UpdateContactInput>;
  sgtsBusinessRelationManager?: Maybe<UpdateContactInput>;
  impact?: Maybe<UpdateSelectionInput>;
  impactId?: Maybe<Scalars['ID']>;
  functionality?: Maybe<UpdateSelectionInput>;
  functionalityId?: Maybe<Scalars['ID']>;
  connectivity?: Maybe<UpdateSelectionInput>;
  connectivityId?: Maybe<Scalars['ID']>;
  accessibility?: Maybe<UpdateSelectionInput>;
  accessibilityId?: Maybe<Scalars['ID']>;
  classification?: Maybe<UpdateSelectionInput>;
  classificationId?: Maybe<Scalars['ID']>;
  bcpCoveringOutage?: Maybe<UpdateSelectionInput>;
  bcpCoveringOutageId?: Maybe<Scalars['ID']>;
  industrialNetwork?: Maybe<UpdateSelectionInput>;
  industrialNetworkId?: Maybe<Scalars['ID']>;
  paloAlto?: Maybe<UpdateSelectionInput>;
  paloAltoId?: Maybe<Scalars['ID']>;
  iDMZ?: Maybe<UpdateSelectionInput>;
  iDMZId?: Maybe<Scalars['ID']>;
  networkSegmentationIndustrial?: Maybe<UpdateSelectionInput>;
  networkSegmentationIndustrialId?: Maybe<Scalars['ID']>;
  cyberX?: Maybe<UpdateSelectionInput>;
  cyberXId?: Maybe<Scalars['ID']>;
  wifi3?: Maybe<UpdateSelectionInput>;
  wifi3Id?: Maybe<Scalars['ID']>;
  comment?: Maybe<Scalars['String']>;
  lastValidatorEmail?: Maybe<Scalars['String']>;
  lastValidationDate?: Maybe<Scalars['Float']>;
  lastAttributeUpdated?: Maybe<Scalars['String']>;
  active?: Maybe<UpdateSelectionInput>;
  activeId?: Maybe<Scalars['ID']>;
  validatedByEmail?: Maybe<UpdateContactInput>;
  lastValidation?: Maybe<Scalars['Float']>;
  magnitude?: Maybe<UpdateMagnitudeInput>;
  magnitudeId?: Maybe<Scalars['ID']>;
  smartR?: Maybe<UpdateSmartRInput>;
  smartRId?: Maybe<Scalars['ID']>;
  closeDate?: Maybe<Scalars['Float']>;
  groupReferentialCode?: Maybe<Scalars['String']>;
  smartRCode?: Maybe<Scalars['String']>;
  editors?: Maybe<Array<Maybe<Scalars['String']>>>;
  reviewers?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Scalars['String']>;
};

export type UpdateSmartRInput = {
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
  sruCode?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  street3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type UpdateSubBusinessUnitContactInput = {
  id: Scalars['ID'];
  subBusinessUnitId: Scalars['ID'];
  scopeId?: Maybe<Scalars['ID']>;
  user?: Maybe<UpdateContactInput>;
  roleId: Scalars['ID'];
};

export type UpdateSubBusinessUnitInput = {
  id: Scalars['ID'];
  businessUnitId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  isitManager?: Maybe<UpdateContactInput>;
};

export type UpdateTranslationInput = {
  id?: Maybe<Scalars['ID']>;
  category?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type UpdateUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  attribute?: Maybe<Scalars['String']>;
  lastValue?: Maybe<Scalars['JSON']>;
  value?: Maybe<Scalars['JSON']>;
  requesterEmail?: Maybe<Scalars['String']>;
  requestDate?: Maybe<Scalars['Float']>;
  siteID?: Maybe<Scalars['String']>;
  subBusinessUnitID?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type UpdateUserInput = {
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  nickName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  features?: Maybe<Array<Feature>>;
  groups?: Maybe<Array<Group>>;
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  BusinessUnit: ResolverTypeWrapper<BusinessUnit>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  String: ResolverTypeWrapper<Scalars['String']>;
  Contact: ResolverTypeWrapper<Contact>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  CreateBusinessUnitInput: CreateBusinessUnitInput;
  CreateContactInput: CreateContactInput;
  CreateFeatureInput: CreateFeatureInput;
  CreateGroupInput: CreateGroupInput;
  CreateMagnitudeInput: CreateMagnitudeInput;
  CreateSelectionInput: CreateSelectionInput;
  CreateSiteInput: CreateSiteInput;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  CreateSmartRInput: CreateSmartRInput;
  CreateSubBusinessUnitContactInput: CreateSubBusinessUnitContactInput;
  CreateSubBusinessUnitInput: CreateSubBusinessUnitInput;
  CreateTranslationInput: CreateTranslationInput;
  CreateUpdateInput: CreateUpdateInput;
  CreateUserInput: CreateUserInput;
  Date: ResolverTypeWrapper<Scalars['Date']>;
  Feature: ResolverTypeWrapper<Feature>;
  Group: ResolverTypeWrapper<Group>;
  JSON: ResolverTypeWrapper<Scalars['JSON']>;
  JSONObject: ResolverTypeWrapper<Scalars['JSONObject']>;
  Localisation: ResolverTypeWrapper<Localisation>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  LocalisationInput: LocalisationInput;
  LoginResponse: ResolverTypeWrapper<LoginResponse>;
  LoginUserInput: LoginUserInput;
  Magnitude: ResolverTypeWrapper<Magnitude>;
  Mutation: ResolverTypeWrapper<{}>;
  PlaceData: ResolverTypeWrapper<PlaceData>;
  Query: ResolverTypeWrapper<{}>;
  ResetPasswordInput: ResetPasswordInput;
  Selection: ResolverTypeWrapper<Selection>;
  SelectionType: SelectionType;
  Site: ResolverTypeWrapper<Site>;
  SmartR: ResolverTypeWrapper<SmartR>;
  SubBusinessUnit: ResolverTypeWrapper<SubBusinessUnit>;
  SubBusinessUnitContact: ResolverTypeWrapper<SubBusinessUnitContact>;
  Translation: ResolverTypeWrapper<Translation>;
  Update: ResolverTypeWrapper<Update>;
  UpdateBusinessUnitInput: UpdateBusinessUnitInput;
  UpdateContactInput: UpdateContactInput;
  UpdateFeatureInput: UpdateFeatureInput;
  UpdateGroupInput: UpdateGroupInput;
  UpdateMagnitudeInput: UpdateMagnitudeInput;
  UpdateSelectionInput: UpdateSelectionInput;
  UpdateSiteInput: UpdateSiteInput;
  UpdateSmartRInput: UpdateSmartRInput;
  UpdateSubBusinessUnitContactInput: UpdateSubBusinessUnitContactInput;
  UpdateSubBusinessUnitInput: UpdateSubBusinessUnitInput;
  UpdateTranslationInput: UpdateTranslationInput;
  UpdateUpdateInput: UpdateUpdateInput;
  UpdateUserInput: UpdateUserInput;
  Upload: ResolverTypeWrapper<Scalars['Upload']>;
  User: ResolverTypeWrapper<User>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  BusinessUnit: BusinessUnit;
  ID: Scalars['ID'];
  String: Scalars['String'];
  Contact: Contact;
  Boolean: Scalars['Boolean'];
  CreateBusinessUnitInput: CreateBusinessUnitInput;
  CreateContactInput: CreateContactInput;
  CreateFeatureInput: CreateFeatureInput;
  CreateGroupInput: CreateGroupInput;
  CreateMagnitudeInput: CreateMagnitudeInput;
  CreateSelectionInput: CreateSelectionInput;
  CreateSiteInput: CreateSiteInput;
  Float: Scalars['Float'];
  CreateSmartRInput: CreateSmartRInput;
  CreateSubBusinessUnitContactInput: CreateSubBusinessUnitContactInput;
  CreateSubBusinessUnitInput: CreateSubBusinessUnitInput;
  CreateTranslationInput: CreateTranslationInput;
  CreateUpdateInput: CreateUpdateInput;
  CreateUserInput: CreateUserInput;
  Date: Scalars['Date'];
  Feature: Feature;
  Group: Group;
  JSON: Scalars['JSON'];
  JSONObject: Scalars['JSONObject'];
  Localisation: Localisation;
  Int: Scalars['Int'];
  LocalisationInput: LocalisationInput;
  LoginResponse: LoginResponse;
  LoginUserInput: LoginUserInput;
  Magnitude: Magnitude;
  Mutation: {};
  PlaceData: PlaceData;
  Query: {};
  ResetPasswordInput: ResetPasswordInput;
  Selection: Selection;
  Site: Site;
  SmartR: SmartR;
  SubBusinessUnit: SubBusinessUnit;
  SubBusinessUnitContact: SubBusinessUnitContact;
  Translation: Translation;
  Update: Update;
  UpdateBusinessUnitInput: UpdateBusinessUnitInput;
  UpdateContactInput: UpdateContactInput;
  UpdateFeatureInput: UpdateFeatureInput;
  UpdateGroupInput: UpdateGroupInput;
  UpdateMagnitudeInput: UpdateMagnitudeInput;
  UpdateSelectionInput: UpdateSelectionInput;
  UpdateSiteInput: UpdateSiteInput;
  UpdateSmartRInput: UpdateSmartRInput;
  UpdateSubBusinessUnitContactInput: UpdateSubBusinessUnitContactInput;
  UpdateSubBusinessUnitInput: UpdateSubBusinessUnitInput;
  UpdateTranslationInput: UpdateTranslationInput;
  UpdateUpdateInput: UpdateUpdateInput;
  UpdateUserInput: UpdateUserInput;
  Upload: Scalars['Upload'];
  User: User;
};

export type BusinessUnitResolvers<ContextType = any, ParentType extends ResolversParentTypes['BusinessUnit'] = ResolversParentTypes['BusinessUnit']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cio?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactResolvers<ContextType = any, ParentType extends ResolversParentTypes['Contact'] = ResolversParentTypes['Contact']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  userPrincipalName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  givenName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  surname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobilePhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  onSite?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isTechnical?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  firstContact?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  viewer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  editor?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  validator?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  globalAdmin?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['Selection']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export type FeatureResolvers<ContextType = any, ParentType extends ResolversParentTypes['Feature'] = ResolversParentTypes['Feature']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['Group'] = ResolversParentTypes['Group']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  features?: Resolver<Maybe<Array<ResolversTypes['Feature']>>, ParentType, ContextType>;
  members?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export interface JsonObjectScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSONObject'], any> {
  name: 'JSONObject';
}

export type LocalisationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Localisation'] = ResolversParentTypes['Localisation']> = {
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  streetNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  route?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locality?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  placeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  utcOffset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LoginResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['LoginResponse'] = ResolversParentTypes['LoginResponse']> = {
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  accessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  refreshToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expiresIn?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  expiresAt?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  featuresToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MagnitudeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Magnitude'] = ResolversParentTypes['Magnitude']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  SRU_Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SRU_Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Entity_Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Entity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Segment_Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Segment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Business_group_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Business_group?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Branche_Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Branche?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  addGroupMember?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationAddGroupMemberArgs, never>>;
  createBusinessUnit?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationCreateBusinessUnitArgs, 'input' | 'broadcast'>>;
  createContact?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCreateContactArgs, 'input' | 'broadcast'>>;
  createFeature?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCreateFeatureArgs, 'input'>>;
  createGroup?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCreateGroupArgs, never>>;
  createMagnitude?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCreateMagnitudeArgs, 'input'>>;
  createSelection?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationCreateSelectionArgs, 'input'>>;
  createSite?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCreateSiteArgs, 'input' | 'broadcast'>>;
  createSmartR?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCreateSmartRArgs, 'input' | 'broadcast'>>;
  createSubBusinessUnit?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationCreateSubBusinessUnitArgs, 'input' | 'broadcast'>>;
  createSubBusinessUnitContact?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationCreateSubBusinessUnitContactArgs, 'input' | 'broadcast'>>;
  createTranslation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCreateTranslationArgs, 'input'>>;
  createUpdate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCreateUpdateArgs, 'input'>>;
  createUser?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCreateUserArgs, 'input'>>;
  deleteBusinessUnits?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteBusinessUnitsArgs, never>>;
  deleteContacts?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteContactsArgs, never>>;
  deleteFeatures?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteFeaturesArgs, 'featureIds'>>;
  deleteGroups?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteGroupsArgs, never>>;
  deleteMagnitudes?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteMagnitudesArgs, never>>;
  deleteSelections?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteSelectionsArgs, never>>;
  deleteSites?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteSitesArgs, never>>;
  deleteSmartRs?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteSmartRsArgs, never>>;
  deleteSubBusinessUnitContacts?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteSubBusinessUnitContactsArgs, never>>;
  deleteSubBusinessUnits?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteSubBusinessUnitsArgs, never>>;
  deleteTranslation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteTranslationArgs, 'id'>>;
  deleteUpdates?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteUpdatesArgs, never>>;
  deleteUsers?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteUsersArgs, never>>;
  disableUser?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDisableUserArgs, 'email'>>;
  enableUser?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationEnableUserArgs, 'email'>>;
  forgotPassword?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationForgotPasswordArgs, 'email'>>;
  removeGroupMember?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationRemoveGroupMemberArgs, never>>;
  resetPassword?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationResetPasswordArgs, 'input'>>;
  unlockUser?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUnlockUserArgs, 'email'>>;
  updateBusinessUnit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUpdateBusinessUnitArgs, 'input'>>;
  updateContact?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUpdateContactArgs, 'input'>>;
  updateFeature?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUpdateFeatureArgs, 'input'>>;
  updateGroup?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUpdateGroupArgs, never>>;
  updateMagnitude?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUpdateMagnitudeArgs, 'input'>>;
  updateSelection?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUpdateSelectionArgs, 'input'>>;
  updateSite?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUpdateSiteArgs, 'input'>>;
  updateSmartR?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUpdateSmartRArgs, 'input'>>;
  updateSubBusinessUnit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUpdateSubBusinessUnitArgs, 'input'>>;
  updateSubBusinessUnitContact?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUpdateSubBusinessUnitContactArgs, 'input'>>;
  updateTranslation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUpdateTranslationArgs, 'input'>>;
  updateUpdate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUpdateUpdateArgs, 'input'>>;
  updateUser?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUpdateUserArgs, 'input'>>;
};

export type PlaceDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlaceData'] = ResolversParentTypes['PlaceData']> = {
  placeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  utcOffset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  streetNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  route?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locality?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  checkEmail?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<QueryCheckEmailArgs, never>>;
  convertAddress?: Resolver<Maybe<ResolversTypes['Localisation']>, ParentType, ContextType, RequireFields<QueryConvertAddressArgs, 'address'>>;
  getPlaceFromPlaceID?: Resolver<Maybe<ResolversTypes['PlaceData']>, ParentType, ContextType, RequireFields<QueryGetPlaceFromPlaceIdArgs, 'placeId'>>;
  getUser?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  listBusinessUnits?: Resolver<Array<Maybe<ResolversTypes['BusinessUnit']>>, ParentType, ContextType>;
  listContacts?: Resolver<Array<Maybe<ResolversTypes['Contact']>>, ParentType, ContextType>;
  listFeatures?: Resolver<Maybe<Array<Maybe<ResolversTypes['Feature']>>>, ParentType, ContextType>;
  listGroups?: Resolver<Array<Maybe<ResolversTypes['Group']>>, ParentType, ContextType>;
  listMagnitudes?: Resolver<Array<Maybe<ResolversTypes['Magnitude']>>, ParentType, ContextType>;
  listSelections?: Resolver<Array<Maybe<ResolversTypes['Selection']>>, ParentType, ContextType>;
  listSites?: Resolver<Array<Maybe<ResolversTypes['Site']>>, ParentType, ContextType>;
  listSitesCache?: Resolver<Array<Maybe<ResolversTypes['JSON']>>, ParentType, ContextType>;
  listSmartRs?: Resolver<Array<Maybe<ResolversTypes['SmartR']>>, ParentType, ContextType>;
  listSubBusinessUnitContacts?: Resolver<Array<Maybe<ResolversTypes['SubBusinessUnitContact']>>, ParentType, ContextType>;
  listSubBusinessUnits?: Resolver<Array<Maybe<ResolversTypes['SubBusinessUnit']>>, ParentType, ContextType>;
  listTranslations?: Resolver<Maybe<Array<Maybe<ResolversTypes['Translation']>>>, ParentType, ContextType>;
  listUpdates?: Resolver<Array<Maybe<ResolversTypes['Update']>>, ParentType, ContextType>;
  listUsers?: Resolver<Array<Maybe<ResolversTypes['User']>>, ParentType, ContextType>;
  login?: Resolver<Maybe<ResolversTypes['LoginResponse']>, ParentType, ContextType, RequireFields<QueryLoginArgs, 'input'>>;
  renew?: Resolver<Maybe<ResolversTypes['LoginResponse']>, ParentType, ContextType, RequireFields<QueryRenewArgs, never>>;
  resetPasswordCTA?: Resolver<Maybe<ResolversTypes['LoginResponse']>, ParentType, ContextType, RequireFields<QueryResetPasswordCtaArgs, 'input'>>;
  searchPlace?: Resolver<Maybe<Array<Maybe<ResolversTypes['PlaceData']>>>, ParentType, ContextType, RequireFields<QuerySearchPlaceArgs, 'address'>>;
};

export type SelectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Selection'] = ResolversParentTypes['Selection']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['SelectionType'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  color?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SiteResolvers<ContextType = any, ParentType extends ResolversParentTypes['Site'] = ResolversParentTypes['Site']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hpsCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sgtCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sruCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shared?: Resolver<Maybe<ResolversTypes['Selection']>, ParentType, ContextType>;
  sharedId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  activity?: Resolver<Maybe<ResolversTypes['Selection']>, ParentType, ContextType>;
  activityId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  displayLoc?: Resolver<Maybe<ResolversTypes['Selection']>, ParentType, ContextType>;
  displayLocId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  employeeCount?: Resolver<Maybe<ResolversTypes['Selection']>, ParentType, ContextType>;
  employeeCountId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  itUserCount?: Resolver<Maybe<ResolversTypes['Selection']>, ParentType, ContextType>;
  itUserCountId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  usualName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  entityName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  localisation?: Resolver<Maybe<ResolversTypes['Localisation']>, ParentType, ContextType>;
  subBusinessUnitId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  subBusinessUnit?: Resolver<Maybe<ResolversTypes['SubBusinessUnit']>, ParentType, ContextType>;
  regionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['Selection']>, ParentType, ContextType>;
  managingDirector?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType>;
  cfo?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType>;
  siteManager?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType>;
  itManager?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType>;
  automationManager?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType>;
  webAppContacts?: Resolver<Maybe<Array<Maybe<ResolversTypes['Contact']>>>, ParentType, ContextType>;
  industrialContacts?: Resolver<Maybe<Array<Maybe<ResolversTypes['Contact']>>>, ParentType, ContextType>;
  digitalChampions?: Resolver<Maybe<Array<Maybe<ResolversTypes['Contact']>>>, ParentType, ContextType>;
  sgtsAssignement?: Resolver<Maybe<ResolversTypes['Selection']>, ParentType, ContextType>;
  sgtsAssignementId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  sgtsSecurityOfficer?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType>;
  sgtsBusinessRelationManager?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType>;
  impact?: Resolver<Maybe<ResolversTypes['Selection']>, ParentType, ContextType>;
  impactId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  functionality?: Resolver<Maybe<ResolversTypes['Selection']>, ParentType, ContextType>;
  functionalityId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  connectivity?: Resolver<Maybe<ResolversTypes['Selection']>, ParentType, ContextType>;
  connectivityId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  accessibility?: Resolver<Maybe<ResolversTypes['Selection']>, ParentType, ContextType>;
  accessibilityId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  classification?: Resolver<Maybe<ResolversTypes['Selection']>, ParentType, ContextType>;
  classificationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  bcpCoveringOutage?: Resolver<Maybe<ResolversTypes['Selection']>, ParentType, ContextType>;
  bcpCoveringOutageId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  industrialNetwork?: Resolver<Maybe<ResolversTypes['Selection']>, ParentType, ContextType>;
  industrialNetworkId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  paloAlto?: Resolver<Maybe<ResolversTypes['Selection']>, ParentType, ContextType>;
  paloAltoId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  iDMZ?: Resolver<Maybe<ResolversTypes['Selection']>, ParentType, ContextType>;
  iDMZId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  networkSegmentationIndustrial?: Resolver<Maybe<ResolversTypes['Selection']>, ParentType, ContextType>;
  networkSegmentationIndustrialId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  cyberX?: Resolver<Maybe<ResolversTypes['Selection']>, ParentType, ContextType>;
  cyberXId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  wifi3?: Resolver<Maybe<ResolversTypes['Selection']>, ParentType, ContextType>;
  wifi3Id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastValidatorEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastValidationDate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastAttributeUpdated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  magnitude?: Resolver<Maybe<ResolversTypes['Magnitude']>, ParentType, ContextType>;
  smartR?: Resolver<Maybe<ResolversTypes['SmartR']>, ParentType, ContextType>;
  active?: Resolver<Maybe<ResolversTypes['Selection']>, ParentType, ContextType>;
  activeId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  closeDate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  groupReferentialCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  smartRCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  editors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  reviewers?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SmartRResolvers<ContextType = any, ParentType extends ResolversParentTypes['SmartR'] = ResolversParentTypes['SmartR']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sruCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  siteName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubBusinessUnitResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubBusinessUnit'] = ResolversParentTypes['SubBusinessUnit']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  businessUnitId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  businessUnit?: Resolver<Maybe<ResolversTypes['BusinessUnit']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isitManager?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType>;
  contacts?: Resolver<Maybe<Array<ResolversTypes['SubBusinessUnitContact']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubBusinessUnitContactResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubBusinessUnitContact'] = ResolversParentTypes['SubBusinessUnitContact']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  subBusinessUnitId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  subBusinessUnit?: Resolver<Maybe<ResolversTypes['SubBusinessUnit']>, ParentType, ContextType>;
  scopeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  scope?: Resolver<Maybe<ResolversTypes['Selection']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType>;
  roleId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['Selection']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TranslationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Translation'] = ResolversParentTypes['Translation']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subCategory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  client?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Update'] = ResolversParentTypes['Update']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  attribute?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastValue?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  requesterEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requestDate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  siteID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subBusinessUnitID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UploadScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Upload'], any> {
  name: 'Upload';
}

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nickName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  features?: Resolver<Maybe<Array<ResolversTypes['Feature']>>, ParentType, ContextType>;
  groups?: Resolver<Maybe<Array<ResolversTypes['Group']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  BusinessUnit?: BusinessUnitResolvers<ContextType>;
  Contact?: ContactResolvers<ContextType>;
  Date?: GraphQLScalarType;
  Feature?: FeatureResolvers<ContextType>;
  Group?: GroupResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  JSONObject?: GraphQLScalarType;
  Localisation?: LocalisationResolvers<ContextType>;
  LoginResponse?: LoginResponseResolvers<ContextType>;
  Magnitude?: MagnitudeResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  PlaceData?: PlaceDataResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Selection?: SelectionResolvers<ContextType>;
  Site?: SiteResolvers<ContextType>;
  SmartR?: SmartRResolvers<ContextType>;
  SubBusinessUnit?: SubBusinessUnitResolvers<ContextType>;
  SubBusinessUnitContact?: SubBusinessUnitContactResolvers<ContextType>;
  Translation?: TranslationResolvers<ContextType>;
  Update?: UpdateResolvers<ContextType>;
  Upload?: GraphQLScalarType;
  User?: UserResolvers<ContextType>;
};


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;
