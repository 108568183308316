<mat-form-field class="input2">
  <input
    [formControl]="searchPeopleCtrl"
    [matAutocomplete]="auto"
    matInput
    placeholder="{{ (formName) | translate }}"
  />
  <mat-autocomplete
    [panelWidth]="300"
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="result($event)"
  >
    <mat-option *ngIf="isLoading && searchPeopleCtrl.value?.length > 2">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-option>
    <ng-container *ngIf="!isLoading && searchPeopleCtrl.value?.length > 2">
      <mat-option *ngFor="let user of filteredPeople" [value]="user" style="min-height: 48px; line-height: normal; height: auto; padding: 10px 20px;">
        <span>{{ user.displayName }}</span><br />
        <small class="text-grey"><i>{{ user.mail }}</i></small>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>