import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class SecurityService {
    private readonly _securitySource = new BehaviorSubject<boolean>(true);
    readonly security$ = this._securitySource.asObservable();

    unlock(password: string) {
        if (password === '+e:zr:YssT5nZV=W') {
            this._securitySource.next(true)
        }
    }
}