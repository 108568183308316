import {
  Component,
  ElementRef,
  Input,
  AfterViewInit,
  ViewChild,
  OnChanges,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'app-kpi',
  templateUrl: 'kpi.component.html'
})
export class KpiComponent implements AfterViewInit, OnChanges {
  @Input() duration: number;
  @Input() digit: number;
  current = 0;
  @Input() steps: number;
  @ViewChild('animatedDigit') animatedDigit: ElementRef;

  animateCount() {
    if (!this.duration) {
      this.duration = 1000;
    }

    if (typeof this.digit === 'number') {
      this.counterFunc(this.digit, this.duration, this.animatedDigit);
    }
  }

  counterFunc(endValue: number, durationMs: number, element: ElementRef) {
    if (!this.steps) {
      this.steps = 12;
    }

    const stepCount = Math.abs(durationMs / this.steps);
    const valueIncrement = (endValue - 0) / stepCount;
    const sinValueIncrement = Math.PI / stepCount;

    let currentValue = this.current;
    let currentSinValue = 0;

    function plusstep() {
      currentSinValue += sinValueIncrement;
      currentValue += valueIncrement * Math.sin(currentSinValue) ** 2 * 2;

      if (element?.nativeElement)
        element.nativeElement.textContent = Math.abs(Math.floor(currentValue));

      if (currentSinValue < Math.PI) {
        window.requestAnimationFrame(plusstep);
      }
    }
    function minusstep() {
      currentSinValue -= sinValueIncrement;
      currentValue -= valueIncrement * Math.sin(currentSinValue) ** 2 * 2;

      if (element?.nativeElement)
        element.nativeElement.textContent = Math.abs(Math.floor(currentValue));

      if (currentSinValue < Math.PI) {
        window.requestAnimationFrame(minusstep);
      }
    }
    if (this.current < endValue) plusstep();
    else if (this.current > endValue) minusstep();
    else this.current = endValue;
  }

  ngAfterViewInit() {
    if (typeof this.digit === 'number') {
      this.animateCount();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['digit']) {
      if (this.digit === 0) {
        if (this.animatedDigit) {
          this.animatedDigit.nativeElement.textContent = 0;
        }
        this.current = 0;
      } else {
        this.animateCount();
      }
    }
  }
}
