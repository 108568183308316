import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  constructor() {
    this._selectedTheme$.subscribe((newTheme) => {
      const { classList } = document.body;
      const classesString = classList.toString();
      const classes = classesString ? classesString.split(' ') : [];
      classes.forEach((className) => {
        if (className.indexOf('theme-') > -1) document.body.classList.remove(className);
      });
      document.body.classList.add(newTheme);
    });
    const storage = localStorage.getItem('theme');
    if (storage) this._selectedTheme$.next(storage);
  }
  public _selectedTheme$ = new BehaviorSubject<string>('theme-default');

  setTheme(themeName: string) {
    this._selectedTheme$.next(themeName);
    localStorage.setItem('theme', themeName);
  }

  public get currentTheme() {
    return this._selectedTheme$.value;
  }

  public get availableThemes() {
    return ['theme-default', 'theme-green'];
  }
}
