import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import {
  CreateTranslationInput,
  LoaderService,
  Translation,
  UpdateTranslationInput
} from 'src/app/modules/shared';
import { SignalrService } from './signalr.service';
import { AuthService } from './auth-azure.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  private readonly _translationsSource = new BehaviorSubject<Translation[]>([]);
  readonly translation$ = this._translationsSource.asObservable();

  constructor(
    private apollo: Apollo,
    private signalrService: SignalrService,
    private loaderService: LoaderService,
    private authService: AuthService
  ) {
    this.refresh();
    this.signalrService.connection.on('translations', (element) => {
      this.refresh();
    });
    this.authService.token$.subscribe(() => this.refresh());
  }

  public getTranslationsValue() {
    return this._translationsSource.value;
  }

  refresh(): void {
    //this.loaderService.addLoader('translation', 'list');
    const REQ = gql`
      query listTranslations {
        listTranslations {
          id
          category
          subCategory
          language
          client
          key
          value
        }
      }
    `;

    this.apollo
      .watchQuery<any>({
        query: REQ,
        variables: {}
      })
      .valueChanges.subscribe(({ data, loading }) => {
        this._translationsSource.next(data.listTranslations);
        //this.loaderService.removeLoader('translation', 'list');
      });
  }

  async create(translation: CreateTranslationInput) {
    this.loaderService.addLoader('translation', 'create');
    const REQ = gql`
      mutation createTranslation($input: CreateTranslationInput!) {
        createTranslation(input: $input)
      }
    `;
    let result = false;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          input: translation
        }
      })
      .subscribe(({ data }) => {
        this.loaderService.removeLoader('translation', 'create');
        result = data.createTranslation;
      });
    return result;
  }

  async update(translation: UpdateTranslationInput) {
    this.loaderService.addLoader('translation', 'update');
    const REQ = gql`
      mutation updateTranslation($input: UpdateTranslationInput!) {
        updateTranslation(input: $input)
      }
    `;
    let result = false;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          input: translation
        }
      })
      .subscribe(({ data }) => {
        this.loaderService.removeLoader('translation', 'update');
        result = data.createTranslation;
      });
    return result;
  }

  async delete(translation: Translation) {
    this.loaderService.addLoader('translation', 'delete');
    const REQ = gql`
      mutation deleteTranslation($id: String!) {
        deleteTranslation(id: $id)
      }
    `;
    let result = false;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          id: translation.id
        }
      })
      .subscribe(({ data }) => {
        this.loaderService.removeLoader('translation', 'delete');
        result = data.createTranslation;
      });
    return result;
  }
}
