import { Injectable } from '@angular/core';
import { AuthService } from './auth-azure.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { CreateFeatureInput, Feature, LoaderService, UpdateFeatureInput } from 'src/app/modules/shared';
import { Apollo, gql } from 'apollo-angular';
import { SignalrService } from './signalr.service';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class FeatureService {
  private readonly _featuresSource = new BehaviorSubject<Feature[]>([]);
  readonly features$ = this._featuresSource.asObservable();

  constructor(
    private apollo: Apollo,
    private signalrService: SignalrService,
    private loaderService: LoaderService,
    private authService: AuthService
  ) {
    this.refresh();
    this.signalrService.connection.on('features', (element) => {
      this.refresh();
    });
    this.authService.token$.subscribe(() => this.refresh());
  }

  getValue() {
    return this._featuresSource.getValue();
  }

  async refresh() {
    this.loaderService.addLoader('feature', 'list');
    const REQ = gql`
      query listFeatures {
        listFeatures {
          id
          key
        }
      }
    `;
    this._featuresSource.next(
      _.sortBy(
        await this.apollo
          .query<any>({
            query: REQ,
            variables: {}
          })
          .pipe(map((result) => {
            this.loaderService.removeLoader('feature', 'list');
            return _.sortBy(result?.data?.listFeatures, 'key')
          }))
          .toPromise(),
        'key'
      )
    );
  }

  async create(feature: CreateFeatureInput) {
    this.loaderService.addLoader('feature', 'create');
    const REQ = gql`
      mutation createFeature($input: CreateFeatureInput!) {
        createFeature(input: $input)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          input: feature
        }
      })
      .subscribe(() => this.loaderService.removeLoader('feature', 'create'));
  }

  async update(feature: UpdateFeatureInput) {
    this.loaderService.addLoader('feature', 'update');
    const REQ = gql`
      mutation updateFeature($input: UpdateFeatureInput!) {
        updateFeature(input: $input)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          input: feature
        }
      })
      .subscribe(() => this.loaderService.removeLoader('feature', 'update'));
  }
  async delete(features: Feature[]) {
    this.loaderService.addLoader('feature', 'delete');
    const REQ = gql`
      mutation deleteFeatures($featureIds: [ID!]!) {
        deleteFeatures(featureIds: $featureIds)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          featureIds: features.map((feature) => feature.id)
        }
      })
      .subscribe(() => this.loaderService.removeLoader('feature', 'delete'));
    this._featuresSource.next(
      this._featuresSource
        .getValue()
        .filter((item) => features.map((feature) => feature.id).indexOf(item.id))
    );
  }
}
