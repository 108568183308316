import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { BehaviorSubject, filter, firstValueFrom, Subject, takeUntil } from 'rxjs';
import { User } from 'src/app/modules/shared';
import { environment } from '../../../../environments/environment';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly _userSource = new BehaviorSubject<User>(null);
  readonly user$ = this._userSource.asObservable();

  private readonly _tokenSource = new BehaviorSubject<string>(null);
  readonly token$ = this._tokenSource.asObservable();

  private readonly _authenticatedSource = new BehaviorSubject<boolean>(false);
  readonly authenticated$ = this._authenticatedSource.asObservable();

  constructor(
    private msAuthService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private http: HttpClient
  ) {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.msAuthService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setAuthenticated();
        this.checkAndSetActiveAccount();
        this.setToken()
      });
  }

  public get tokenValue() {
    return this._tokenSource.value;
  }

  public get userValue() {
    return this._userSource.value;
  }

  public get authenticated() {
    return this.msAuthService.instance.getAllAccounts().length > 0;
  }


  setAuthenticated() {
    this._authenticatedSource.next(this.msAuthService.instance.getAllAccounts().length > 0);
  }

  async setToken() {
    let token = ''
    const accounts = this.msAuthService.instance.getAllAccounts();

    if (accounts.length) {
      const accessTokenRequest = {
        scopes: ["user.read"],
        account: accounts[0]
      }

      const payload = await firstValueFrom(this.msAuthService.acquireTokenSilent(accessTokenRequest))
      if (payload) {
        token = payload.accessToken;
      }

    } else {
      this.login()
    }

    localStorage.setItem('token', token)
    this._tokenSource.next(token)
  }

  checkAndSetActiveAccount() {
    let account = this.msAuthService.instance.getActiveAccount();
    if (!account && this.msAuthService.instance.getAllAccounts().length > 0) {
      let accounts = this.msAuthService.instance.getAllAccounts();
      account = accounts[0]
      this.msAuthService.instance.setActiveAccount(account);
    }
    if (account) {
      this.getProfile()
    }
  }

  login() {
    this.msAuthService.loginRedirect();
  }

  logout() { // Add log out function here
    this.msAuthService.logoutRedirect({
      postLogoutRedirectUri: environment.hpssites_url
    });
  }

  getProfile() {
    this.http.get(GRAPH_ENDPOINT)
      .subscribe((profile: any) => {
        if (profile) {
          this._userSource.next({
            id: profile.id,
            firstName: profile.givenName,
            lastName: profile.surname,
            email: profile.mail ? profile.mail : profile.userPrincipalName,
          })
        }
      });
  }
}
