import { Pipe, PipeTransform, Injectable } from '@angular/core';
import * as _ from 'lodash';
@Pipe({
  name: 'filter'
})
@Injectable()
export class FilterPipe implements PipeTransform {
  transform(items: any[], field: string, value: any): any[] {
    if (!items) {
      return [];
    }
    if (!field || !value) {
      return items;
    }
    return items.filter((singleItem) => {
      if (typeof value === 'number') return singleItem[field] === value;
      else return singleItem[field].toLowerCase().includes(value.toLowerCase());
    });
  }
}
@Pipe({
  name: 'filterObject'
})
export class FilterObjectPipe implements PipeTransform {
  public transform(
    value: any[] | null,
    keys: string,
    term: string,
    page?: number,
    perPage?: number
  ) {
    let filtered: any[] | null;
    if (!value) {
      return [];
    }
    if (!term || term.length < 3) {
      filtered = value;
    } else {
      filtered = (value || []).filter((item) =>
        keys
          .split(',')
          .some((key) => _.get(item, key) && new RegExp(term, 'gi').test(_.get(item, key)))
      );
    }
    if (!perPage || !page) {
      return filtered;
    }
    return filtered.slice((page - 1) * perPage, page * perPage);
  }
}
