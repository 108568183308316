import { HttpClient } from '@angular/common/http';
import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';
import { catchError, firstValueFrom, map, take } from 'rxjs';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/users'
@Injectable({
    providedIn: 'root'
})
export class GraphService {
    constructor(
        private http: HttpClient
    ) {

    }
    async search(input: string) {
        let splitted = input.split(" ");
        try {
            if (splitted.length == 1) {
                return await firstValueFrom(await this.http.get(GRAPH_ENDPOINT + "?$filter=startswith(givenName,'" + splitted[0] + "') or startswith(surname,'" + splitted[0] + "') or startswith(mail,'" + splitted[0] + "')").pipe(
                    take(1),
                    map((result: any) => {
                        if (result.value.length == 0) {
                            return [{mail: splitted[0]}]
                        }
                        return result.value
                    })
                ));
            }
            else {
                return await firstValueFrom(await this.http.get(GRAPH_ENDPOINT + "?$filter=(startswith(givenName,'" + splitted[0] + "') and startswith(surname,'" + splitted[1] + "')) or (startswith(givenName,'" + splitted[1] + "') and startswith(surname,'" + splitted[0] + "'))").pipe(
                    take(1),
                    map((result: any) => {
                        return result.value
                    })
                ));
            }
        }
        catch (error) {
            return null
        }
    }
    async getUser(email: string) {
        try {
            return await firstValueFrom(await this.http.get(GRAPH_ENDPOINT + '/' + email).pipe(
                take(1),
                map((result) => result)
            ));
        }
        catch (error) {
            return null
        }
    }
}