import { Injectable } from '@angular/core';
import { LoginResponse } from 'src/app/modules/shared';

import * as signalr from '@microsoft/signalr';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  public connection;
  messages: any[] = [];
  constructor() {
    this.connection = new signalr.HubConnectionBuilder()
      .withUrl('/api/signalr', {
        accessTokenFactory: () => {
          const storage = localStorage.getItem('session');
          if (storage) {
            const session: LoginResponse = JSON.parse(storage);
            return session.accessToken;
          }
          return '';
        }
      })
      .withAutomaticReconnect()
      .build();
    this.connection.on('send', (data) => {
      console.log(data);
    });

    this.connection.onclose(function () {
      console.log('signalr disconnected');
    });
    this.connection.onreconnecting((err) => console.log('signalr reconnecting  ', err));
    this.connection.start().then(() => this.connection.invoke('send', 'Start'));
  }
  send(message: any) {
    this.connection.invoke('send', message);
  }
}
