import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LanguageLoader } from 'src/app/modules/shared/services';
import { MaterialModule } from '../material/material.module';
import { FilterObjectPipe, DurationFormatPipe } from './pipes';
import { AvatarModule } from 'ngx-avatar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TableComponent } from './components/table/table.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MapsGoogleComponent } from './components/maps-google/maps-google.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { SearchAddressComponent } from './components/searchAddress/searchAddress.component';
import { SearchPeopleComponent } from './components/searchPeople/searchPeople.component';
import { KpiComponent } from './components/kpi/kpi.component';
import { GaugeComponent } from './components/gauge/gauge.component';
import { UploadComponent } from './components/upload/upload.component';
import { RouterModule } from '@angular/router';
import { SearchPeopleMultipleComponent } from './components/searchPeopleMultiple/searchPeopleMultiple.component';
import { SearchPeopleOnSiteComponent } from './components/searchPeopleOnSite/searchPeopleOnSite.component';
import { SearchPeopleIsTechnicalComponent } from './components/searchPeopleIsTechnical/searchPeopleIsTechnical.component';
import { SearchPeopleFirstContactComponent } from './components/searchPeopleFirstContact/searchPeopleFirstContact.component';
import { searchPeopleDigitalChampionComponent } from './components/searchPeopleDigitalChampion/searchPeopleDigitalChampion.component';
import { searchPeopleMultipleDigitalChampionsComponent } from './components/searchPeopleMultipleDigitalChampions/searchPeopleMultipleDigitalChampions.component'; 

const components = [
  TableComponent,
  FilterObjectPipe,
  DurationFormatPipe,
  LoaderComponent,
  MapsGoogleComponent,
  SearchAddressComponent,
  SearchPeopleComponent,
  SearchPeopleMultipleComponent,
  searchPeopleDigitalChampionComponent,
  searchPeopleMultipleDigitalChampionsComponent,
  SearchPeopleFirstContactComponent,
  KpiComponent,
  GaugeComponent,
  UploadComponent,
  SearchPeopleOnSiteComponent,
  SearchPeopleIsTechnicalComponent
];

@NgModule({
  imports: [
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AvatarModule,
    GoogleMapsModule,
    RouterModule,
    TranslateModule.forChild({
      loader: { provide: TranslateLoader, useClass: LanguageLoader }
    })
  ],
  declarations: [components],
  exports: [components, TranslateModule],
  providers: []
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [FilterObjectPipe, DurationFormatPipe]
    };
  }
}
