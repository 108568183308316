import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { CreateSubBusinessUnitInput, SubBusinessUnit, LoaderService, UpdateSubBusinessUnitInput } from 'src/app/modules/shared';
import { map } from 'rxjs/operators';
import { SignalrService } from './signalr.service';
import * as _ from 'lodash';
import { ContactService } from '.';

@Injectable({
  providedIn: 'root'
})
export class SubBusinessUnitService {
  private readonly _subBusinessUnitsSource = new BehaviorSubject<SubBusinessUnit[]>([]);
  readonly subBusinessUnits$ = this._subBusinessUnitsSource.asObservable();

  constructor(
    private apollo: Apollo,
    private signalrService: SignalrService,
    private loaderService: LoaderService,
    private contactService: ContactService
  ) {
    this.refresh();
    this.signalrService.connection.on('subBusinessUnits', () => {
      this.refresh();
    });
  }

  getValue() {
    return this._subBusinessUnitsSource.getValue();
  }

  getSbu(sbuId) {
    return this._subBusinessUnitsSource.getValue().filter(elm => elm.id === sbuId)[0];
  }

  async refresh() {
    this.loaderService.addLoader('subBusinessUnit', 'list');
    const REQ = gql`
      query listSubBusinessUnits {
        listSubBusinessUnits {
          id
          title
          isitManager  {
            userPrincipalName
            givenName
            surname
            mail
            mobilePhone
          }
          businessUnit {
            id
            title
            cio  {
              userPrincipalName
              givenName
              surname
              mail
              mobilePhone
            }
          }
          contacts {
            id
            user {
              userPrincipalName
              givenName
              surname
              mail
              mobilePhone
            }
            scope {
              id
              code
              title
            }
            role {
              id
              code
              title
            }
          }
        }
      }
    `;
    this._subBusinessUnitsSource.next(
      await this.apollo
        .query<any>({
          query: REQ,
          variables: {}
        })
        .pipe(map((result) => {
          this.loaderService.removeLoader('subBusinessUnit', 'list');
          return _.sortBy(result?.data?.listSubBusinessUnits || [], 'bu')
        }))
        .toPromise()
    );
  }
  async create(subBusinessUnit: CreateSubBusinessUnitInput, broadcast: boolean = true) {
    this.loaderService.addLoader('subBusinessUnit', 'create');
    const REQ = gql`
      mutation createSubBusinessUnit($input: CreateSubBusinessUnitInput!, $broadcast: Boolean!) {
        createSubBusinessUnit(input: $input, broadcast: $broadcast)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          input: subBusinessUnit,
          broadcast: broadcast
        }
      })
      .toPromise();
    this.loaderService.removeLoader('subBusinessUnit', 'create');
  }
  async update(subBusinessUnit: UpdateSubBusinessUnitInput) {
    this.loaderService.addLoader('subBusinessUnit', 'update');
    const REQ = gql`
      mutation updateSubBusinessUnit($input: UpdateSubBusinessUnitInput!) {
        updateSubBusinessUnit(input: $input)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          input: subBusinessUnit
        }
      })
      .toPromise();
    this.loaderService.removeLoader('subBusinessUnit', 'update');
  }
  async delete(subBusinessUnits: SubBusinessUnit[]) {
    this.loaderService.addLoader('subBusinessUnit', 'delete');
    const REQ = gql`
      mutation deleteSubBusinessUnits($subBusinessUnitIds: [ID!]) {
        deleteSubBusinessUnits(subBusinessUnitIds: $subBusinessUnitIds)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          subBusinessUnitIds: subBusinessUnits.map((subBusinessUnit) => subBusinessUnit.id)
        }
      })
      .toPromise();
    this.loaderService.removeLoader('subBusinessUnit', 'delete');
  }
}
