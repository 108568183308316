import { gql } from 'apollo-angular';

export const LIST_USERS = gql`
  query listSites {
    listSites {
      id
      title
    }
  }
`;
