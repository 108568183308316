import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { CreateContactInput, Contact, LoaderService, UpdateContactInput, SiteService, Site } from 'src/app/modules/shared';
import { map } from 'rxjs/operators';
import { SignalrService } from './signalr.service';
import * as _ from 'lodash';
import { toLower } from 'lodash';
import { AuthService } from '.';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private readonly _contactsSource = new BehaviorSubject<Contact[]>([]);
  readonly contacts$ = this._contactsSource.asObservable();

  private readonly _authorizedSource = new BehaviorSubject<string>('default');
  readonly isAuthorized$ = this._authorizedSource.asObservable();

  tmpContactList = {}

  constructor(
    private apollo: Apollo,
    private signalrService: SignalrService,
    private loaderService: LoaderService,
    private siteService: SiteService,
    private authService: AuthService
  ) {
    this.refresh();
    this.signalrService.connection.on('contacts', (element) => {
      this.refresh();
    });
    this.authService.token$.subscribe(() => {
      this.refresh()
    })
    this.contacts$.subscribe(() => {
      this.authService.user$.subscribe(() => {
        const me = this.authService.userValue?.email;
        if (this.isAuthorized(me))
          this._authorizedSource.next('ok')
        else if (this.isAuthorized(me) === null)
          this._authorizedSource.next('default')
        else
          this._authorizedSource.next('nok')
      })
    })
  }

  getValue() {
    return this._contactsSource.getValue();
  }

  getContact(mail) {
    return this._contactsSource.getValue().filter(contact => contact.mail === mail);
  }

  async refresh() {
    this.loaderService.addLoader('contact', 'list');
    const REQ = gql`
      query listContacts {
        listContacts {
          id
          userPrincipalName
          givenName
          surname
          mail
          mobilePhone
          onSite
          isTechnical
          firstContact
          viewer
          editor
          validator
          globalAdmin
        }
      }
    `;
    this._contactsSource.next(
      await this.apollo
        .query<any>({
          query: REQ,
          variables: {}
        })
        .pipe(map((result) => {
          this.loaderService.removeLoader('contact', 'list');
          return _.sortBy(result?.data?.listContacts || [], 'SRU_Code')
        }))
        .toPromise()
    );
  }

  async create(contact: CreateContactInput, broadcast: boolean = true) {
    this.loaderService.addLoader('contact', 'create');
    const REQ = gql`
      mutation createContact($input: CreateContactInput!, $broadcast: Boolean!) {
        createContact(input: $input, broadcast: $broadcast)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          input: contact,
          broadcast: broadcast
        }
      })
      .toPromise();
    this.loaderService.removeLoader('contact', 'create');
  }

  async update(contact: UpdateContactInput) {
    this.loaderService.addLoader('contact', 'update');
    const REQ = gql`
      mutation updateContact($input: UpdateContactInput!) {
        updateContact(input: $input)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          input: contact
        }
      })
      .toPromise();
    this.loaderService.removeLoader('contact', 'update');
  }

  async delete(contacts: Contact[]) {
    this.loaderService.addLoader('contact', 'delete');
    const REQ = gql`
      mutation deleteContacts($contactIds: [ID!]) {
        deleteContacts(contactIds: $contactIds)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          contactIds: contacts.map((contact) => contact.id)
        }
      })
      .toPromise();
    this.loaderService.removeLoader('contact', 'delete');
  }

  addUser(user) {
    let existingContact = this.getContact(user?.mail)[0]

    if (!user['userPrincipalName'])
      user['userPrincipalName'] = user.mail

    // New contact
    if (existingContact === undefined || !existingContact) {

      if (!(user['firstContact']))
        user['firstContact'] = false
      if (!(user['onSite']))
        user['onSite'] = false
      if (!(user['isTechnical']))
        user['isTechnical'] = false

      user['viewer'] = true
      user['globalAdmin'] = false
      this.create(user)
    }
    else {
    }
  }

  async pushUsers() {
    for (let contact in this.tmpContactList) {
      this.addUser(this.tmpContactList[contact])
      await new Promise(r => setTimeout(r, 200));
    }

  }

  isAuthorized(mail) {
    const contacts = this.getContact(mail)
    const contact = contacts?.length ? contacts[0] : null
    if (contact?.viewer)
      return true
    else if (contact === null)
      return null
    return false
  }

  isEditor(site: Site, mail: string) {
    let contact = this.getContact(mail)[0]

    if (contact) {
      if (site?.subBusinessUnit?.isitManager?.mail === mail)
        return true

      if (site?.subBusinessUnit?.businessUnit?.cio?.mail === mail)
        return true

      if (site?.subBusinessUnit?.contacts) {
        for (let contact of site?.subBusinessUnit?.contacts){
          if (site?.region?.title === contact.scope.title ) {
            if (contact.user.mail === mail)
              return true
          }
        }
      }

      for (let editor of this.siteService.siteEditors) {
        if (this.siteService.multipleContact.includes(editor))
        {
          if(site[editor]){
            for(let contact of site[editor])
            {
              if (contact?.mail === mail)
                return true
            }
          }
        }
        else {
          if (site[editor]?.mail === mail)
            return true
        }
      }
    }

    return false
  }

  isValidator(site, mail) {
    let contact = this.getContact(mail)[0]

    if (contact) {
      if (site?.subBusinessUnit?.isitManager?.mail === mail)
        return true
    }

    return false
  }

  isValidatorSomewhere(mail) {
    let contact = this.getContact(mail)[0]

    let sitesId = []

    if (contact) {
      for (let site of this.siteService.getValue()){
        if (site?.subBusinessUnit?.isitManager?.mail === mail) {
          sitesId = [...sitesId, site?.subBusinessUnitId]
        }
      }
    }

    return [...new Set(sitesId)];
  }

  isGlobalAdmin(mail) {
    let contact = this.getContact(mail)[0]
    
    if (contact?.globalAdmin)
      return true

    return false
  }

  getUserSites(mail) {
    return this.siteService.getValue().filter(site => this.isEditor(site, mail))
  }

  cleanContact(contact) {
    Object.keys(contact).forEach((key) => ['id', 'userPrincipalName', 'givenName', 'surname', 'mail', 'mobilePhone', 'firstContact', 'onSite', 'isTechnical', 'viewer', 'editor', 'validator', 'globalAdmin', 'role'].includes(key) || delete contact[key]);
    return contact
  }

  // addViwerRight() {

  // }
}
