import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-upload',
  templateUrl: 'upload.component.html'
})
export class UploadComponent {
  constructor(
    public dialogRef: MatDialogRef<UploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    const result: any = {};
    this.data.attributes.forEach((attribute: any) => {
      result[attribute.name] = new FormControl(
        attribute.select,
        attribute.mandatory ? [Validators.required] : []
      );
    });
    this.form = new FormGroup(result);
  }
  @Output() itemAdd: EventEmitter<{
    item: any;
    broadcast: boolean;
  }> = new EventEmitter();
  @Output() itemUpdate: EventEmitter<{
    item: any;
    broadcast: boolean;
  }> = new EventEmitter();
  full: boolean;
  file: File;
  itemList: any[];
  worksheet: any;
  identical: number;
  common: number;
  destination: number;
  create: number;
  update: number;
  delete: number;
  total: number;
  done: number;
  form: FormGroup;
  sourceData: any[];
  source: number;
  sheetColumns: string[];
  addfile(event: any) {
    this.file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = (e) => {
      const arrayBuffer: any = fileReader.result;
      const data = new Uint8Array(arrayBuffer);
      const arr = [];
      for (let i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const first_sheet_name = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[first_sheet_name];
      this.sheetColumns = XLSX.utils.sheet_to_json(this.worksheet, { header: 1 })[0] as string[];
    };
  }
  generateList() {
    this.done = 0;
    const source = XLSX.utils
      .sheet_to_json(this.worksheet, { raw: true })
      .map((line: any, index) => {
        const result: any = {};
        this.data?.attributes.forEach((attribute: any) => {
          result[attribute.name] =
            attribute.type === 'string'
              ? (line[this.form.value[attribute.name]] ? line[this.form.value[attribute.name]] + '' : '').trim()
              : line[this.form.value[attribute.name]] || attribute.default;
        });
        return result;
      });
    this.sourceData = source;
    this.source = source.length;
    const attributes = this.data?.attributes.map((attribute: any) => attribute.name);
    const destination = _.map(this.data.destinationService, (o) => _.pick(o, attributes));
    this.destination = destination.length;
    this.total = source.length;
    const multiKeys = (item: any) => {
      let result = '';
      this.data.keys.forEach((value: string) => {
        result += item[value] + '_';
      });
      return result;
    };

    const toCreate = _.differenceBy(source, destination, multiKeys);
    this.create = toCreate.length;

    const toDelete = _.differenceBy(destination, source, multiKeys);
    this.delete = toDelete.length;

    const common = _.intersectionBy(source, destination, multiKeys);
    this.common = common.length;

    const toUpdate = _.differenceWith(common, destination, _.isEqual);
    
    this.update = toUpdate.length;
    this.identical = this.common - this.update;
    this.itemList = toCreate.concat(toUpdate);
  }
  async upload() {
    this.itemList.forEach((itemToUpdate: any, i) => {
      setTimeout(() => {
        this.itemAdd.emit({
          item: itemToUpdate,
          broadcast: this.update + this.create > this.done + 1 ? false : true
        });
        //TODO BUG NOT FILTERING CORRECTLY
        this.itemList = this.itemList.filter((item: any) => {
          let result = true;
          this.data.keys.forEach((value: any) => {
            if (itemToUpdate[value] !== item[value]) result = false;
          });
          return result;
        });
        this.done = i + 1;
      }, i * 100);
    });
  }
  async uploadFull() {
    this.sourceData.forEach(async (itemToUpdate: any, i) => {
      await setTimeout(() => {
        this.itemAdd.emit({
          item: itemToUpdate,
          broadcast: this.source > this.done + 1 ? false : true
        });
        //TODO BUG NOT FILTERING CORRECTLY
        this.sourceData = this.sourceData.filter((item: any) => {
          let result = true;
          this.data.keys.forEach((value: any) => {
            if (itemToUpdate[value] !== item[value]) result = false;
          });
          return result;
        });
        this.done = i + 1;
      }, i * 50);
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  diff(obj1, obj2) {
    const result = {};
    if (Object.is(obj1, obj2)) {
      return undefined;
    }
    if (!obj2 || typeof obj2 !== 'object') {
      return obj2;
    }
    Object.keys(obj1 || {}).concat(Object.keys(obj2 || {})).forEach(key => {
      if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
        result[key] = obj2[key];
      }
      if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
        const value = this.diff(obj1[key], obj2[key]);
        if (value !== undefined) {
          result[key] = value;
        }
      }
    });
    return result;
  }
}
