import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { CreateUpdateInput, Update, LoaderService, UpdateUpdateInput } from 'src/app/modules/shared';
import { map } from 'rxjs/operators';
import { SignalrService } from './signalr.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  private readonly _updatesSource = new BehaviorSubject<Update[]>([]);
  readonly listUpdates$ = this._updatesSource.asObservable();

  constructor(
    private apollo: Apollo,
    private signalrService: SignalrService,
    private loaderService: LoaderService
  ) {
    this.refresh();
    this.signalrService.connection.on('updates', (element) => {
      this.refresh();
    });
  }
  getValue() {
    return this._updatesSource.getValue();
  }
  async refresh() {
    this.loaderService.addLoader('update', 'list');
    const REQ = gql`
      query listUpdates {
        listUpdates {
          id
          attribute
          lastValue
          value
          requesterEmail
          requestDate
          siteID
          subBusinessUnitID
          type
          status
        }
      }
    `;
    this._updatesSource.next(
      await this.apollo
        .query<any>({
          query: REQ,
          variables: {}
        })
        .pipe(map((result) => {
          this.loaderService.removeLoader('update', 'list');
          return _.sortBy(result?.data?.listUpdates || [], 'requestDate')
        }))
        .toPromise()
    );
  }
  async create(update: CreateUpdateInput) {
    this.loaderService.addLoader('update', 'create');
    const REQ = gql`
      mutation createUpdate($input: CreateUpdateInput!) {
        createUpdate(input: $input)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          input: update
        }
      })
      .toPromise();
    this.loaderService.removeLoader('update', 'create');
  }
  async update(update: UpdateUpdateInput) {
    this.loaderService.addLoader('update', 'update');
    const REQ = gql`
      mutation updateUpdate($input: UpdateUpdateInput!) {
        updateUpdate(input: $input)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          input: update
        }
      })
      .toPromise();
    this.loaderService.removeLoader('update', 'update');
  }
  async delete(updates: Update[]) {
    this.loaderService.addLoader('update', 'delete');
    const REQ = gql`
      mutation deleteUpdates($updateIds: [ID!]) {
        deleteUpdates(updateIds: $updateIds)
      }
    `;
    await this.apollo
      .mutate<any>({
        mutation: REQ,
        variables: {
          updateIds: updates.map((update) => update.id)
        }
      })
      .toPromise();
    this.loaderService.removeLoader('update', 'delete');
  }
}
