import { Injectable } from '@angular/core';
import { Localisation, PlaceData } from 'src/app/modules/shared';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {
  constructor(private apollo: Apollo) { }

  async search(address: string): Promise<PlaceData[]> {
    const AUTOCOMPLETE = gql`
      query searchPlace($address: String!) {
        searchPlace(address: $address) {
          placeId
          address
        }
      }
    `;
    if (address) {
      return await this.apollo
        .query<any>({
          query: AUTOCOMPLETE,
          variables: {
            address: address
          }
        })
        .pipe(map((result) => result.data.searchPlace))
        .toPromise();
    } else return [];
  }
  async getPlace(placeId: string): Promise<PlaceData> {
    const GET_PLACE = gql`
      query getPlaceFromPlaceID($placeId: String!) {
        getPlaceFromPlaceID(placeId: $placeId) {
          placeId
          address
          streetNumber
          route
          country
          locality
          longitude
          latitude
          utcOffset
        }
      }
    `;
    return await this.apollo
      .query<any>({
        query: GET_PLACE,
        variables: {
          placeId: placeId
        }
      })
      .pipe(map((result) => result.data.getPlaceFromPlaceID))
      .toPromise();
  }
  async convertAddress(address: string): Promise<Localisation> {
    const GET_PLACE = gql`
      query convertAddress($address: String!) {
        convertAddress(address: $address) {
          placeId
          address
          longitude
          latitude
          utcOffset
          postalCode
          streetNumber
          route
          locality
          country
          region
        }
      }
    `;
    return await this.apollo
      .query<any>({
        query: GET_PLACE,
        variables: {
          address: address
        }
      })
      .pipe(map((result) => result.data.convertAddress))
      .toPromise();
  }
}
