import { Component, Input, Output, OnInit, OnChanges, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, tap, switchMap } from 'rxjs/operators';
import { GraphService } from 'src/app/modules/shared';
import { of } from 'rxjs';

@Component({
  selector: 'app-search-people',
  templateUrl: './searchPeople.component.html',
  styleUrls: ['./searchPeople.component.scss']
})
export class SearchPeopleComponent implements OnInit, OnChanges {
  @Input() selected: any | null | undefined;
  @Input() formName: string
  @Input() isReadOnly = false;

  @Output() selectedChange: EventEmitter<string> = new EventEmitter();

  searchPeopleCtrl = new FormControl();
  filteredPeople: any[];
  isLoading = false;
  errorMsg: string;

  constructor(private graphService: GraphService) { }

  async ngOnInit() {

    this.searchPeopleCtrl.setValue(this.selected, { emitEvent: false });
    if (this.isReadOnly) this.searchPeopleCtrl.disable();
    this.searchPeopleCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.errorMsg = '';
          this.filteredPeople = [];
          this.isLoading = true;
        }),
        switchMap((value) => {
          if (value && value !== '' && (typeof value === 'string' || typeof value === 'object')){
            if (typeof value === 'object') {
              return of(null);
            }
            else {
              let result = this.graphService.search(value);
              return this.graphService.search(value);
            }
          }
          else {
            this.selectedChange.emit({} as any);
            return of(null);
          }
        })
      )
      .subscribe((data: any[]) => {
        if (data === undefined || data === null) {
          this.errorMsg = 'error';
          this.filteredPeople = [];
        } else {
          this.isLoading = false;
          this.errorMsg = '';
          this.filteredPeople = data;
        }
      });
  }

  async ngOnChanges(changes: any) {
    for (const propName in changes) {
      // eslint-disable-next-line no-prototype-builtins
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'isReadOnly': {
            if (this.isReadOnly === true) {
              this.searchPeopleCtrl.disable();
            } else {
              this.searchPeopleCtrl.enable();
            }
            break;
          }
          case 'selected': {
            this.searchPeopleCtrl.setValue(this.selected, { emitEvent: false });
          }
        }
      }
    }
  }

  displayFn(people) {
    if (people?.surname != undefined && people?.givenName != undefined) {
      return people?.surname + ', ' + people?.givenName
    }
    else {
      return people?.mail
    }
  }

  async result(event: any) {
    if (event?.option?.value) {
      this.selectedChange.emit({...event.option.value, firstContact: false, onSite: false, isTechnical: false} as any);
    }
  }
}
