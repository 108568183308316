<div>
  <div style="display: flex; align-items: center;">
  <app-search-people-is-technical [(selected)]="current" [formName]="formName"></app-search-people-is-technical>
  <button mat-icon-button (click)="addItem()"><mat-icon style="margin-bottom: 5px;">add</mat-icon></button>
  </div>
  <div class="scrolling-wrapper">
      <span *ngFor="let item of selected" class="card">
        {{ item.mail }}
        <button mat-icon-button (click)="deleteItem(item)">
          <mat-icon>delete</mat-icon>
        </button>
      </span>
  </div>
</div>