import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private readonly _loaderSource = new BehaviorSubject<Boolean>(false);
  readonly loader$ = this._loaderSource.asObservable();
  private readonly _loaderListSource = new BehaviorSubject<string[]>([]);
  readonly loaderList$ = this._loaderListSource.asObservable();
  timer: Observable<0>;
  _timer: Subscription;
  constructor() { }

  setLoader(value: boolean) {
    this._timer?.unsubscribe();
    this._loaderSource.next(value);
    if (value === true) {
      this.timer = timer(25000);
      this._timer = this.timer.subscribe(() => {
        this._loaderSource.next(false);
        this._loaderListSource.next([])
      });
    }
  }

  addLoader(type: string, action: string) {
    if (type && action) {
      this._loaderListSource.next([
        ...this._loaderListSource.getValue(),
        type + ' ' + action
      ])
      this.setLoader(true)
    }
  }
  removeLoader(type: string, action: string) {
    if (type && action) {
      setTimeout(() => {
        this._loaderListSource.next(this._loaderListSource.getValue().filter(element => element !== type + ' ' + action))
        this._loaderSource.next(this._loaderListSource.getValue().length ? true : false)
      }, 500)
    }
  }
}
